import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

import { PhoneNumberCountryCodeMapperService } from '../services/phone-number/phone-number-code-mapper.service';
import { containsOnlyPhoneCharacters, getLengthValidationError } from '../services/utils/validator-utils';

const phoneUtil = PhoneNumberUtil.getInstance();

interface PhoneNumberValue {
  phoneNoCountry: string;
  countryCode: string;
}

export const phoneNumberValidators = (): ValidatorFn => {
  return (control: AbstractControl): Record<string, boolean> | undefined => {
    const value = control.value as PhoneNumberValue;
    if (!value) return undefined;

    const { phoneNoCountry: phoneNumber, countryCode: dialCode } = value;

    if (!phoneNumber) return { requiredPhone: true };
    if (!containsOnlyPhoneCharacters(phoneNumber)) return { IS_NOT_INTEGER: true };
    if (phoneNumber.length === 1) return { TOO_SHORT: true };

    const phoneNumberCountryCodeMapperService = new PhoneNumberCountryCodeMapperService();
    const countryCode = phoneNumberCountryCodeMapperService.getCountryCodeByDialCode(dialCode);
    const internationalNumber = `${dialCode}${phoneNumber}`;

    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(internationalNumber, countryCode);
      return validateParsedNumber(parsedNumber);
    } catch (error) {
      return { INVALID_NUMBER_FORMAT: true };
    }
  };
};

export function validateParsedNumber(parsedNumber: PhoneNumber): Record<string, boolean> | undefined {
  const validNumberLength = phoneUtil.isPossibleNumberWithReason(parsedNumber);
  if (validNumberLength) {
    return getLengthValidationError(validNumberLength);
  }
  return phoneUtil.isValidNumber(parsedNumber) ? undefined : { INVALID_NUMBER_FORMAT: true };
}
