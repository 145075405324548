<div
  [ngClass]="{
    focus: focus,
    'error-label': !isPhoneNumberValid && phoneControl.errors
  }"
  class="phone-label"
>
  {{ placeholder }}
</div>
<ng-template #noLabelPlace>
  <div class="h-4">&nbsp;</div>
</ng-template>
<ngx-mat-intl-tel-input
  #ipn
  (focusin)="focusIn()"
  (focusout)="focusOut($event)"
  (keyup)="checkIsPhone()"
  *ngIf="!phoneControl.disabled"
  [preferredCountries]="['fr']"
  [enablePlaceholder]="false"
  [formControl]="phoneControl"
  [matTooltipPosition]="'before'"
  [maxlength]="15"
  [ngClass]="{
    'error-label': !isPhoneNumberValid && phoneControl.errors,
    focus: focus
  }"
  [required]="isRequired"
  class="block border-b border-gray-secondary relative"
  matTooltip="{{ i18nService._('Txt_Tooltip_Phone') }}"
  name="fieldName"
  data-cy="Téléphone"
>
</ngx-mat-intl-tel-input>
<mat-error class="h-4 pl-4">
  <span>
    {{ i18nService._(errorMessage) }}
  </span>
</mat-error>

<ng-template #placeForError>
  <mat-error *ngIf="isPhoneNumberValid" class="h-4">
    <span>&nbsp;</span>
  </mat-error>
</ng-template>

<input
  *ngIf="phoneControl.disabled"
  [formControl]="phoneControl"
  [placeholder]="placeholder"
  [required]="isRequired"
  class="block border-b border-gray-secondary"
  disabled
  matInput
  maxlength="255"
  name="fieldName"
  type="text"
  data-cy="Téléphone"
/>
