import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { ReplaySubject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgIf } from '@angular/common';

import { CountryData, InputPhoneOption } from '../../shared/model/input-phone-option';
import { countriesFr } from '../../shared/model/countries.fr';

export interface PhoneValue {
  phoneE164: string;
  phoneNoCountry?: string;
  countryCode?: string;
}

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, FormsModule, InputTextModule],
})
export class InputPhoneComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() cssClass!: string;
  @Input() E164PhoneNumber!: string | null;
  @Input() label!: string;
  @Input() labelCssClass!: string;
  @Input() name = 'intl-tel-input-name';
  @Input() onlyLocalized!: boolean;
  @Input() options: InputPhoneOption = {};
  @Input() required!: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() inputPhoneControl: UntypedFormControl;
  @Output() private E164PhoneNumberChange = new EventEmitter<PhoneValue>();
  @ViewChild('intlTelInput') private _inputElement!: ElementRef;

  private $destroy: ReplaySubject<boolean> = new ReplaySubject(1);

  private _phoneNumber!: string;

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: any) {
    if (value === this._phoneNumber) return; // Avoid infinite loop
    value = typeof value === 'string' ? value : value.phoneNoCountry;
    if (value) {
      this._intlTelInput.setNumber(value);
      this.inputPhoneControl.markAsPristine();
      this.inputPhoneControl.markAsUntouched();
    } else {
      // If phone number is not set (undefined or null) we set number to empty because null/undefined value is not supported by setNumber
      this._intlTelInput.setNumber('');
    }
    this._phoneNumber = value;
    this.i18nizePhoneNumber();
  }

  private _intlTelInput: intlTelInput.Plugin;

  get intlTelInput(): intlTelInput.Plugin {
    return this._intlTelInput;
  }

  private static modifyCountryData(): void {
    window['intlTelInputGlobals']
      .getCountryData()
      .forEach((country: CountryData) => (country.name = country.name.replace(/.+\((.+)\)/, '$1')));
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.options = { ...this.options, autoPlaceholder: 'off' };
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  ngAfterViewInit(): void {
    if (this.onlyLocalized) {
      InputPhoneComponent.modifyCountryData();
    }

    this._intlTelInput = intlTelInput(this._inputElement.nativeElement, {
      ...this.options,
      localizedCountries: countriesFr,
      preferredCountries: ['fr'],
    });
    this.updatePhoneNumber(this.inputPhoneControl.value);
    this.inputPhoneControl.valueChanges
      .pipe(
        takeUntil(this.$destroy),
        debounceTime(300), // Ajoute un délai pour éviter des changements trop fréquents
        distinctUntilChanged(), // Évite de traiter la même valeur plusieurs fois de suite
        filter((value) => this.shouldUpdatePhoneNumber(value)), // Filtre les valeurs non pertinentes
      )
      .subscribe((value) => {
        this.updatePhoneNumber(value);
      });
  }

  private shouldUpdatePhoneNumber(value: any): boolean {
    if (value == null) return true;
    // In case of object, we check if phoneE164 or phoneNoCountry has changed to skip the value change
    if (typeof value === 'object') {
      if (value.phoneE164 === this.phoneNumber || value.phoneNoCountry === this.phoneNumber) return false;
    }
    return true;
  }

  private updatePhoneNumber(value: any): void {
    if (value) {
      this.phoneNumber = value?.phoneE164 ? value?.phoneE164 : value;
    } else {
      this.phoneNumber = null;
    }
  }

  i18nizePhoneNumber(): void {
    this.E164PhoneNumber = undefined;
    if (this._intlTelInput.isValidNumber()) {
      this.E164PhoneNumber = this._intlTelInput.getNumber();
    }
    const phoneValue: PhoneValue = {
      phoneE164: this.E164PhoneNumber,
      phoneNoCountry: this._intlTelInput.getNumber(intlTelInputUtils.numberFormat.NATIONAL).replace(/\s/g, ''),
      countryCode: `+${this._intlTelInput.getSelectedCountryData().dialCode}`,
    };
    this.phoneNumber = phoneValue.phoneNoCountry; // In case of phone is not typed but loaded by inputPhoneControl, we need to format the displayed phone
    this.E164PhoneNumberChange.emit(phoneValue);
    this.inputPhoneControl.setValue(phoneValue);
    this.inputPhoneControl.markAsDirty();
    this.inputPhoneControl.markAsTouched();
  }
}
