import { PhoneNumberUtil } from 'google-libphonenumber';
export function getLengthValidationError(lengthValidation: any): { [key: string]: boolean } | undefined {
  const validationResult = PhoneNumberUtil.ValidationResult;
  switch (lengthValidation) {
    case validationResult.INVALID_COUNTRY_CODE:
      return { INVALID_COUNTRY_CODE: true };
      break;
    case validationResult.TOO_SHORT:
      return { TOO_SHORT: true };
      break;
    case validationResult.TOO_LONG:
      return { TOO_LONG: true };
      break;
    case validationResult.INVALID_LENGTH:
      return { INVALID_LENGTH: true };
      break;
    default:
      return { INVALID_NUMBER_FORMAT: true };
      break;
  }
}

export function containsOnlyPhoneCharacters(value: string): boolean {
  const onlyPhoneCharactersRegex = new RegExp('^[0-9-+. ]*$');
  const isIntegerValidate = value ? onlyPhoneCharactersRegex.test(value) : true;

  return isIntegerValidate;
}
