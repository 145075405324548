import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { SafeHtml } from '@angular/platform-browser';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { SignatureService } from '../../../../../signature/services/signature.service';
import { UpdateDialogComponent } from '../../../../../signature/components/update-dialog/update-dialog.component';
import { I18nService } from '../../../../../utils/services/i18n.service';
import { IconComponent } from '../../../../standalone/icon/icon.component';

@Component({
  selector: 'app-step-head-title',
  templateUrl: './step-head-title.component.html',
  styleUrls: ['./step-head-title.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent],
})
export class StepHeadTitleComponent implements OnChanges {
  @Input() title: string | SafeHtml;
  @Input() showReload: boolean;
  canReload: boolean;
  @Input() showDuplicate: boolean;
  @Input() showCustomAction: boolean;
  @Input() customActionTitle: string;
  @Input() customActionEvent: () => void;
  @Input() model: any;
  @Input() currentStep: FormlyFieldConfig;

  public titleHtml: SafeHtml;
  public titleText: string;

  constructor(
    private readonly dialog: MatDialog,
    public readonly i18nService: I18nService,
    private readonly route: ActivatedRoute,
    public readonly signatureService: SignatureService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'title') {
        if (`${this.title}`.startsWith('SafeValue')) {
          this.titleHtml = this.title;
          this.titleText = undefined;
        } else {
          this.titleHtml = undefined;
          this.titleText = `${this.title}`;
        }
      }
    }
  }

  resetDossierData() {
    const dossierId = this.route.snapshot.params.signatureId;
    this.signatureService.resetDossierById(dossierId).subscribe((dossierResponce) => {
      if (dossierResponce) {
        location.reload();
      }
    });
  }

  openUpdateDialog() {
    const updateDialog = this.dialog.open(UpdateDialogComponent);
    updateDialog.afterClosed().subscribe((shouldUpdate: boolean) => {
      if (shouldUpdate) {
        this.resetDossierData();
      }
    });
  }

  prefillAcq2() {
    this.signatureService.openPrefillDialog();
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field) {
      if (field.hide) {
        return true;
      }

      if (field.props.disabled && this.model[field.key.toString()] !== null && this.model[field.key.toString()] !== undefined) {
        return true;
      }

      if (field.fieldGroup?.length) {
        return field.fieldGroup.every((f) => this.isValid(f));
      }

      if (field.key) {
        return !!field?.formControl?.valid;
      } else {
        return true;
      }
    }
    return false;
  }
}
