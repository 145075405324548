import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAnchor]',
  standalone: true,
})
export class AnchorDirective {
  constructor(private readonly elementRef: ElementRef) {}

  scrollToElement() {
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
