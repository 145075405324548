import { get, has, indexOf, isArray, isEmpty, isFinite, isMap, isNil, isObject, isSet, isString, keys } from 'lodash-es';

import { Expression, Member, Operator } from './meta-expression';

export class MetaExpressionService {
  private static operatorFunction: {
    [keys in Operator]?: (firstMember: Member, secondMember: Member | Member[], data: unknown) => boolean | number;
  } = {
    [Operator.GT]: this.greaterThan.bind(this),
    [Operator.LT]: this.lesserThan.bind(this),
    [Operator.LTE]: this.lesserThanOrEquale.bind(this),
    [Operator.GTE]: this.greaterThanOrEquale.bind(this),
    [Operator.EQ]: this.equal.bind(this),
    [Operator.NEQ]: this.notEqual.bind(this),
    [Operator.IN]: this.in.bind(this),
    [Operator.DIVIDE]: this.divide.bind(this),
    [Operator.MULTIPLE]: this.multiple.bind(this),
    [Operator.ADD]: this.add.bind(this),
    [Operator.SUBSTRACT]: this.substract.bind(this),
    [Operator.AND]: this.and.bind(this),
    [Operator.OR]: this.or.bind(this),
    [Operator.SIZE]: this.size.bind(this),
    [Operator.GET]: this.get.bind(this),
    [Operator.EMPTY]: this.empty.bind(this),
    [Operator.NOT_EMPTY]: this.notEmpty.bind(this),
    [Operator.NULL]: this.isNull.bind(this),
    [Operator.NOT_NULL]: this.notNull.bind(this),
  };

  static findByField(object: unknown, path: string) {
    return get(object, path);
  }

  static computeExpressionOrMember(expression: Expression | Member, data: unknown = undefined) {
    if ('operator' in expression) {
      return this.computeExpression(expression as Expression, data);
    } else {
      return this.computeMember(expression as Member, data);
    }
  }

  static computeExpression(expression: Expression, data: unknown = undefined): boolean | number {
    if (expression.operator in this.operatorFunction) {
      return this.operatorFunction[expression.operator](expression.firstMember, expression.secondMember, data);
    } else {
      throw new Error(`error MetaExpressionService.computeExpression(): operator ${expression.operator} is not managed`);
    }
  }

  static computeMember(member: Member, data: unknown = undefined) {
    if (keys(member).length != 1) {
      throw new Error(
        `error MetaExpressionService.computeMember(): member ${JSON.stringify(member)} is not valid. It contains more than one property`,
      );
    }
    if (has(member, 'value')) {
      return member.value;
    }
    if (has(member, 'field')) {
      if (!data) {
        throw new Error(`error MetaExpressionService.computeMember() if(member.field): you can't search with field without data`);
      }
      return this.findByField(data, member.field);
    }
    if (has(member, 'expression')) {
      return this.computeExpression(member.expression, data);
    }
    throw new Error(
      `error MetaExpressionService.computeMember(): member ${JSON.stringify(
        member,
      )} is not valid. Properties 'value', 'field' and 'expression' are missing`,
    );
  }

  private static greaterThan(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    if (isNil(firstMemberValue) || isNil(secondMemberValue)) {
      return false;
    }
    return firstMemberValue > secondMemberValue;
  }

  private static greaterThanOrEquale(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    if (isNil(firstMemberValue) || isNil(secondMemberValue)) {
      return false;
    }
    return firstMemberValue >= secondMemberValue;
  }

  private static lesserThan(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    if (isNil(firstMemberValue) || isNil(secondMemberValue)) {
      return false;
    }
    return firstMemberValue < secondMemberValue;
  }

  private static lesserThanOrEquale(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    if (isNil(firstMemberValue) || isNil(secondMemberValue)) {
      return false;
    }
    return firstMemberValue <= secondMemberValue;
  }

  private static equal(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    return firstMemberValue === secondMemberValue;
  }

  private static notEqual(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    return firstMemberValue !== secondMemberValue;
  }

  private static in(firstMember: Member, secondMember: Member[], data: unknown = undefined): boolean | number {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMembers(firstMember, data, secondMember);
    if (isNil(firstMemberValue) || isNil(secondMemberValue)) {
      return false;
    }
    return indexOf(secondMemberValue, firstMemberValue) >= 0;
  }

  private static divide(firstMember: Member, secondMember: Member, data: unknown = undefined): number {
    let { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    firstMemberValue = Number(firstMemberValue);
    secondMemberValue = Number(secondMemberValue);
    if (secondMemberValue === 0) {
      throw new Error(`error MetaExpressionService.divide(): secondMemberValue:${secondMemberValue} === 0 `);
    }
    if (isFinite(firstMemberValue) && isFinite(secondMemberValue) && secondMemberValue !== 0) {
      return firstMemberValue / secondMemberValue;
    } else {
      throw new Error(
        `error MetaExpressionService.divide(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `,
      );
    }
  }

  private static multiple(firstMember: Member, secondMember: Member, data: unknown = undefined): number {
    let { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    firstMemberValue = Number(firstMemberValue);
    secondMemberValue = Number(secondMemberValue);
    if (isFinite(firstMemberValue) && isFinite(secondMemberValue)) {
      return firstMemberValue * secondMemberValue;
    } else {
      throw new Error(
        `error MetaExpressionService.multiple(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `,
      );
    }
  }

  private static add(firstMember: Member, secondMember: Member, data: unknown = undefined): number {
    let { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    firstMemberValue = Number(firstMemberValue);
    secondMemberValue = Number(secondMemberValue);
    if (isFinite(firstMemberValue) && isFinite(secondMemberValue)) {
      return firstMemberValue + secondMemberValue;
    } else {
      throw new Error(
        `error MetaExpressionService.add(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `,
      );
    }
  }

  private static substract(firstMember: Member, secondMember: Member, data: unknown = undefined): number {
    let { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    firstMemberValue = Number(firstMemberValue);
    secondMemberValue = Number(secondMemberValue);
    if (isFinite(firstMemberValue) && isFinite(secondMemberValue)) {
      return firstMemberValue - secondMemberValue;
    } else {
      throw new Error(
        `error MetaExpressionService.substract(): one of those value [firstMemberValue:${firstMemberValue},secondMemberValue:${secondMemberValue}] is not a number `,
      );
    }
  }

  private static or(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    return firstMemberValue || secondMemberValue;
  }

  private static and(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const { firstMemberValue, secondMemberValue } = this.extractLeftAndRightMember(firstMember, data, secondMember);
    return firstMemberValue && secondMemberValue;
  }

  private static size(firstMember: Member, secondMember: Member, data: unknown = undefined): number {
    const firstMemberValue = this.extractLeftMember(firstMember, data);
    if (!firstMemberValue) {
      return 0;
    }
    if (isArray(firstMemberValue) || isString(firstMemberValue)) {
      return firstMemberValue.length;
    } else {
      throw new Error(`error MetaExpressionService.size(): firstMemberValue is not an Array or a string.`);
    }
  }

  private static empty(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const firstMemberValue = this.extractLeftMember(firstMember, data);
    if (
      isArray(firstMemberValue) ||
      isObject(firstMemberValue) ||
      isSet(firstMemberValue) ||
      isString(firstMemberValue) ||
      isMap(firstMemberValue)
    ) {
      return isEmpty(firstMemberValue);
    } else {
      return firstMemberValue === undefined || firstMemberValue == null;
    }
  }

  private static notEmpty(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    return !this.empty(firstMember, secondMember, data);
  }

  private static isNull(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    const firstMemberValue = this.extractLeftMember(firstMember, data);
    return isNil(firstMemberValue);
  }

  private static notNull(firstMember: Member, secondMember: Member, data: unknown = undefined): boolean {
    return !this.isNull(firstMember, secondMember, data);
  }

  private static get(firstMember: Member, secondMember: Member, data: unknown = undefined) {
    if (firstMember.expression) {
      throw new Error(`error MetaExpressionService.get(): can't get form an expression `);
    }
    return this.extractLeftMember(firstMember, data);
  }

  private static extractLeftAndRightMember(firstMember: Member, data: unknown, secondMember: Member) {
    const firstMemberValue = this.computeMember(firstMember, data);
    const secondMemberValue = this.computeMember(secondMember, data);
    return { firstMemberValue, secondMemberValue };
  }

  private static extractLeftMember(firstMember: Member, data: unknown) {
    return this.computeMember(firstMember, data);
  }

  private static extractLeftAndRightMembers(firstMember: Member, data: unknown, secondMember: Member[]) {
    const firstMemberValue = this.computeMember(firstMember, data);
    const secondMemberValue = [];
    for (const member of secondMember) {
      const value = this.computeMember(member, data);
      secondMemberValue.push(value);
    }
    return { firstMemberValue, secondMemberValue };
  }
}
