import { formatCurrency } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { map, Observable, startWith, tap } from 'rxjs';
import { ChargeTypeEnum } from 'libs/commons-dto/src/lib/dossier-prospect';
import { v4 as uuidV4 } from 'uuid';
import { FormlyHookConfig } from '@ngx-formly/core/lib/models';

import { DossierProspectService } from './dossier-prospect.service';
import { CompanyProgramSearch } from './company-program-search.service';
import { DossierProspectType } from '../model/dossier-prospect-type.enum';
import { DossierProspectMapper } from '../model/dossier-prospect.mapper';
import { DossierProspectParamFormData } from '../model/forms/dossier-prospect-param-form-data.model';

import { TokenService } from './../../utils/services/authorisation/token.service';
import { I18nService } from './../../utils/services/i18n.service';
import { RevenueTypeEnum } from './../../../../../../libs/commons-dto/src/lib/dossier-prospect/simulation/revenue/revenu-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DossierProspectFormlyService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private _i18nService: I18nService,
    private _tokenService: TokenService,
    private _dossierProspect: DossierProspectService,
    private _programSearch: CompanyProgramSearch,
  ) {}

  //**************Forms*********************/

  getFormProspect(
    dossierId?: number,
    isEditMode = false,
    displayCompany = false,
    data: DossierProspectParamFormData = undefined,
  ): FormlyFieldConfig[] {
    return [
      {
        type: 'stepper',
        props: {
          ...data,
          validStepForNext: true,
          lastPageButton: isEditMode ? 'Prospect_Modify_Submit' : 'Prospect_Create_Submit',
          disabledRightSideBar: true,
        },
        fieldGroup: this.getAllStepsProspect(dossierId, isEditMode, displayCompany),
      },
    ];
  }

  getFormProspectForOption(data: any): FormlyFieldConfig[] {
    return [
      {
        props: {
          disabledRightSideBar: true,
        },
        fieldGroup: this.getAllStepsProspectForOption(data),
      },
    ];
  }

  getFormSimulation(isMultipleProspect: boolean): FormlyFieldConfig[] {
    return [
      {
        key: 'id',
        className: 'mb-0',
      },
      {
        type: 'accordion',
        fieldGroup: [this.getRevenuesSimulation(isMultipleProspect), this.getChargesSimulation(), this.getLoanSimulation()],
      },
    ];
  }

  getFormPaternity(newProspect = false, hidePaternity = false): FormlyFieldConfig[] {
    if (hidePaternity) {
      return [
        {
          type: 'stepper',
          props: {
            disabledRightSideBar: true,
            backButtonLabel: this._i18nService._('txt_back'),
            goBackRoute: 'PreviousUrl',
            lastPageButton: 'Prospect_Create_Submit',
            deactivateScrollTop: true,
            stickySideBar: true,
          },
          fieldGroup: [...this.getAllStepsProspect()],
        },
      ];
    } else if (!newProspect) {
      return [
        {
          type: 'stepper',
          props: {
            disabledRightSideBar: true,
            backButtonLabel: this._i18nService._('txt_back'),
            goBackRoute: 'PreviousUrl',
            lastPageButton: 'Prospect_Create_Submit',
            deactivateScrollTop: true,
            stickySideBar: true,
          },
          fieldGroup: [
            {
              props: {
                id: 'paternity',
                label: 'Paternity_Stepper_Section',
                isHead: true,
              },
            },
            this.getStepPaternityChooseProspect(),
            this.getStepPaternityProgram(),
          ],
        },
      ];
    } else {
      return [
        {
          type: 'stepper',
          props: {
            disabledRightSideBar: true,
            backButtonLabel: this._i18nService._('txt_back'),
            goBackRoute: 'PreviousUrl',
            lastPageButton: 'Prospect_Create_Submit',
            deactivateScrollTop: true,
            stickySideBar: true,
          },
          fieldGroup: [
            ...this.getAllStepsProspect(),
            {
              props: {
                id: 'paternity',
                label: 'Paternity_Stepper_Section',
                isHead: true,
              },
            },
            this.getStepPaternityProgram(),
          ],
        },
      ];
    }
  }

  //**************Multiple Steps*********************/

  getAllStepsProspect(dossierId?: number, isEditMode = false, displayCompany = true): FormlyFieldConfig[] {
    return [
      this.getStepHead(),
      this.getStepProspectType(dossierId, isEditMode, displayCompany),
      this.getStepProspectCompany(isEditMode),
      this.getStepProspectInfos(1, isEditMode),
      this.getStepProspectInfos(2, isEditMode),
    ];
  }

  //************** Multiple Steps For Option *********************/

  getAllStepsProspectForOption(data: any): FormlyFieldConfig[] {
    return [
      this.getProspectSelect(data),
      this.getStepProspectTypeForOption(),
      this.getTitleForSection(DossierProspectType.COMPANY),
      this.getStepProspectCompany(),
      this.getTitleForSection(DossierProspectType.SOLO),
      this.getStepProspectInfos(1),
      this.getTitleForSection(DossierProspectType.DUO),
      this.getStepProspectInfos(2),
    ];
  }

  //**************Single Step*********************/

  getStepHead(): FormlyFieldConfig {
    return {
      props: {
        id: 'prospect',
        label: 'Prospect_Stepper_Context_Title',
        isHead: true,
      },
      className: 'mb-0',
    };
  }

  getTitleForSection(dossierType: number): FormlyFieldConfig {
    switch (dossierType) {
      case DossierProspectType.SOLO:
        return {
          type: 'text',
          props: {
            label: this._i18nService._('OptionFrom_Acquirer_One'),
            titleLevel: 3,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return (
                field.model.dossier_prospect_type !== DossierProspectType.SOLO &&
                field.model.dossier_prospect_type !== DossierProspectType.DUO
              );
            },
            'props.label': (field: FormlyFieldConfig) =>
              field.parent.model.dossier_prospect_type === DossierProspectType.SOLO
                ? this._i18nService._('OptionFrom_Acquirer')
                : this._i18nService._('OptionFrom_Acquirer_One'),
          },
          className: 'mb-4',
        };
      case DossierProspectType.DUO:
        return {
          type: 'text',
          props: {
            label: this._i18nService._('OptionFrom_Acquirer_Two'),
            titleLevel: 3,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model.dossier_prospect_type !== DossierProspectType.DUO;
            },
          },
          className: 'mb-4',
        };
      case DossierProspectType.COMPANY:
        return {
          type: 'text',
          props: {
            label: this._i18nService._('OptionFrom_Acquirer_Company'),
            titleLevel: 3,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return field.model.dossier_prospect_type !== DossierProspectType.COMPANY;
            },
          },
          className: 'mb-4',
        };
    }
  }

  getProspectSelect(data: any): FormlyFieldConfig {
    return {
      fieldGroup: [
        {
          type: 'info-box',
          className: 'mb-8',
          props: {
            label: this._i18nService._('OptionFrom_Price_Info', [data.lotNumber, data.lotPrice]),
          },
        },
        {
          key: 'dossier_prospect',
          type: 'autocomplete',
          props: {
            label: this._i18nService._('OptionFrom_Selected_Dossier'),
            autoCompleteService: 'dossierProspectByPrescriptor',
            passObject: true,
            dropdown: true,
            required: false,
          },
          expressions: {
            'props.prescriptorId': () => {
              return this._tokenService.getUserId();
            },
          },
          className: 'mb-8',
        },
        {
          type: 'info-box',
          className: 'mb-8',
          props: {
            label: this._i18nService._('OptionFrom_Prospect_Info'),
          },
        },
      ],
    };
  }

  getStepProspectType(dossierId?: number, isEditMode = false, displayCompany = true): FormlyFieldConfig {
    return {
      props: {
        label: 'Prospect_Stepper_Prospect_Type',
        formTitle: isEditMode ? 'Prospect_Stepper_Prospect_Type' : 'Prospect_Stepper_Form_Title',
        transparentBg: true,
        hideStepAction: true,
        stepActionOnFieldKey: 'dossier_prospect_type',
      },
      fieldGroup: [
        {
          key: 'prescriptor_id',
          defaultValue: this._tokenService.getUserId(),
          className: 'mb-0',
        },
        {
          key: 'dossier_id',
          defaultValue: dossierId,
          className: 'mb-0',
        },
        {
          key: 'dossier_prospect_type',
          type: 'radio-cards',
          props: {
            label: 'prospect_stepper_prospect_type',
            required: true,
            radioCardsConfigs: {
              options: this.getOptionsToDisplayForProspectType(displayCompany),
              labelProp: 'label',
              valueProp: 'id',
            },
          },
          className: 'w-full mb-12',
        },
      ],
      fieldGroupClassName: 'mb-0',
      className: 'mb-0',
    };
  }

  getStepProspectTypeForOption(): FormlyFieldConfig {
    return {
      fieldGroup: [
        {
          key: 'dossier_prospect_type',
          type: 'radio',
          props: {
            label: this._i18nService._('OptionFrom_DossierType'),
            required: true,
            options: [
              {
                label: this._i18nService._('OptionFrom_DossierType_Simple_Acquirer_Option'),
                value: DossierProspectType.SOLO,
              },
              {
                label: this._i18nService._('OptionFrom_DossierType_Double_Acquirer_Option'),
                value: DossierProspectType.DUO,
              },
              {
                label: this._i18nService._('OptionFrom_DossierType_Company_Acquirer_Option'),
                value: DossierProspectType.COMPANY,
              },
            ],
          },
          expressions: {
            'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
          },
          className: 'w-full mb-12',
        },
      ],
      fieldGroupClassName: 'mb-0',
      className: 'mb-0',
    };
  }

  getOptionsToDisplayForProspectType(displayCompany) {
    const options = [
      {
        id: DossierProspectType.SOLO,
        icon: 'User',
        label: this._i18nService._('Prospect_Form_Type_Solo'),
        className: 'mb-0',
      },
      {
        id: DossierProspectType.DUO,
        icon: 'Users',
        label: this._i18nService._('Prospect_Form_Type_Duo'),
        className: 'mb-0',
      },
    ];

    if (displayCompany) {
      options.push({
        id: DossierProspectType.COMPANY,
        icon: 'BuildingOffice',
        label: this._i18nService._('Prospect_Form_Type_Company'),
        className: 'mb-0',
      });
    }

    return options;
  }

  getStepProspectCompany(isEditMode = false): FormlyFieldConfig {
    return {
      expressions: {
        hide: (field: FormlyFieldConfig) => field.parent.model.dossier_prospect_type !== DossierProspectType.COMPANY,
      },
      props: {
        label: 'Prospect_Create_Company_Step',
        formTitle: isEditMode ? 'Prospect_Form_Company_Step_Title' : 'Prospect_Stepper_Form_Title',
        headIcon: 'BuildingOffice',
        beforeNext: (form: UntypedFormGroup) => {
          return this.shouldContinueIfNoDuplicate(form);
        },
      },
      fieldGroup: [
        {
          fieldGroup: [
            {
              key: 'company_name',
              type: 'input',
              props: {
                label: this._i18nService._('Prospect_Form_Company_Name'),
                required: false,
                displayOptionalLabel: true,
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
              },
              className: 'flex-1',
            },
            {
              key: 'company_siret',
              type: 'input',
              props: {
                label: this._i18nService._('Prospect_Form_Company_SIRET'),
                required: false,
                displayOptionalLabel: true,
              },
              validators: {
                validation: [Validators.pattern(/^\d{14}$/)],
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
              },
              className: 'flex-1',
            },
          ],
          fieldGroupClassName: 'mb-12 flex gap-6',
        },
        ...this.getConfigProspectInfos(1),
      ],
    };
  }

  getStepProspectInfos(prospectNumber: number, isEditMode = false): FormlyFieldConfig {
    return {
      props: {
        label: `${this._i18nService._('Prospect_Create_Prospect_Personal')}${prospectNumber}`,
        formTitle: isEditMode ? 'Prospect_Form_Personal_Step_Title' : 'Prospect_Stepper_Form_Title',
        headIcon: 'User',
        beforeNext: (form: UntypedFormGroup) => {
          return this.shouldContinueIfNoDuplicate(form);
        },
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          const model = field.parent.model;
          return prospectNumber > 1 || model.dossier_prospect_type === DossierProspectType.COMPANY
            ? model.dossier_prospect_type !== DossierProspectType.DUO
            : false;
        },
        'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
      },
      fieldGroup: [...this.getConfigProspectInfos(prospectNumber)],
    };
  }

  getConfigProspectInfos(prospectNumber: number): FormlyFieldConfig[] {
    return [
      {
        key: 'prospect_' + prospectNumber + '_id',
      },
      {
        key: 'prospect_' + prospectNumber + '_gender',
        type: 'radio',
        props: {
          label: this._i18nService._('Prospect_Form_Personal_Gender'),
          options: [
            {
              label: this._i18nService._('Prospect_Form_Civility_M'),
              value: 'Monsieur',
            },
            {
              label: this._i18nService._('Prospect_Form_Civility_F'),
              value: 'Madame',
            },
          ],
          required: true,
        },
        expressions: {
          'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
        },
        className: 'mb-12 inline-radio',
      },
      {
        fieldGroup: [
          {
            key: 'prospect_' + prospectNumber + '_lastname',
            type: 'input',
            props: {
              required: true,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) =>
                field.parent.model.dossier_prospect_type !== DossierProspectType.COMPANY
                  ? this._i18nService._('Prospect_Form_Personal_Lastname')
                  : this._i18nService._('Prospect_Form_Personal_Lastname_For_Company'),
              'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },
            className: 'flex-1',
          },
          {
            key: 'prospect_' + prospectNumber + '_firstname',
            type: 'input',
            props: {
              required: true,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) =>
                field.parent.model.dossier_prospect_type !== DossierProspectType.COMPANY
                  ? this._i18nService._('Prospect_Form_Personal_Firstname')
                  : this._i18nService._('Prospect_Form_Personal_Firstname_For_Company'),
              'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },
            className: 'flex-1',
          },
        ],
        fieldGroupClassName: 'mb-12 flex gap-6',
      },
      {
        fieldGroup: [
          {
            key: 'prospect_' + prospectNumber + '_email',
            type: 'input',
            props: {
              required: true,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) =>
                field.parent.model.dossier_prospect_type !== DossierProspectType.COMPANY
                  ? this._i18nService._('Prospect_Form_Personal_Email')
                  : this._i18nService._('Prospect_Form_Personal_Email_For_Company'),
              'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },
            validators: {
              validation: ['email'],
            },
            className: 'flex-1',
          },
          {
            key: 'prospect_' + prospectNumber + '_phone',
            type: 'phone',
            props: {
              required: true,
            },
            validators: {
              validation: ['phone'],
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) =>
                field.parent.model.dossier_prospect_type !== DossierProspectType.COMPANY
                  ? this._i18nService._('Prospect_Form_Personal_Phone')
                  : this._i18nService._('Prospect_Form_Personal_Phone_For_Company'),
              'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },

            className: 'flex-1 mb-12',
          },
        ],
        fieldGroupClassName: 'flex gap-6',
      },
      {
        fieldGroup: [
          {
            key: 'prospect_' + prospectNumber + '_country',
            type: 'autocomplete',
            props: {
              label: this._i18nService._('Prospect_Form_Personal_Country'),
              autoCompleteService: 'country',
              displayOptionalLabel: true,
            },
            expressions: {
              'props.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },
            className: 'w-full mb-12',
          },
          {
            key: 'prospect_' + prospectNumber + '_localization',
            type: 'localization',
            props: {
              city: {
                label: this._i18nService._('Prospect_Form_Personal_City'),
              },
              postalCode: {
                type: 'number',
                label: this._i18nService._('Prospect_Form_Personal_Postal_Code'),
              },
              displayOptionalLabel: true,
            },
            expressions: {
              'props.city.readonly': (field: FormlyFieldConfig) => field.parent.model.dossier_prospect,
            },
            className: 'w-full mb-12',
          },
        ],
      },
    ];
  }

  getStepPaternityChooseProspect(): FormlyFieldConfig {
    return {
      props: {
        label: this._i18nService._('Paternity_Create_Existing_Prospect_Step'),
        formTitle: 'Prospect_Paternity_Form_Title',
      },
      fieldGroup: [
        {
          key: 'paternity_create_existing_prospect',
          type: 'autocomplete',
          props: {
            label: this._i18nService._('Prospect_Paternity_Form_Choose_Prospect'),
            autoCompleteService: 'dossierProspect',
            passObject: true,
            required: true,
            forceChoice: true,
          },
        },
      ],
      className: 'w-full mb-4',
    };
  }

  getStepPaternityProgram(newProspect?: boolean): FormlyFieldConfig {
    return {
      props: {
        label: this._i18nService._('Paternity_Add_Company_And_Program_Step'),
        formTitle: 'Prospect_Paternity_Form_Title',
      },
      expressions: {
        'props.headerTitle': (field: FormlyFieldConfig) => {
          const model = field.parent.model;
          return newProspect
            ? model['prospect_1_firstname'] + ' ' + model['prospect_1_lastname']
            : model['paternity_create_existing_prospect']?.label;
        },
      },
      fieldGroup: [
        {
          key: 'paternity_add_company',
          type: 'autocomplete',
          props: {
            label: this._i18nService._('Prospect_Paternity_Form_Add_Company'),
            autoCompleteService: 'devCompany',
            passObject: true,
            forceChoice: true,
            required: true,
            dropdown: true,
          },
          className: 'w-full mb-4',
        },
        {
          key: 'paternity_add_program',
          type: 'select-multiple',
          props: {
            label: this._i18nService._('Prospect_Paternity_Form_Add_Program'),
            optionLabel: 'label',
            required: true,
          },
          expressions: {
            'props.options': () => {
              if (!this._programSearch.programs) return [];
              return this._programSearch.programs
                .map((program) => {
                  return { value: program.id, label: program.programName };
                })
                .sort((a, b) => a.label.localeCompare(b.label, 'fr'));
            },
          },
          className: 'w-full mb-8',
        },
      ],
      hooks: {
        onInit: (f) => {
          return f.form.valueChanges.pipe(
            startWith(f.form.value),
            tap((value) => {
              const companyHasChanged = this._programSearch.updateCompany(value['paternity_add_company']?.id);
              if (companyHasChanged) {
                f.form.controls['paternity_add_program'].setValue(null);
              }
            }),
          );
        },
      },
    };
  }

  getRevenuesSimulation(isMultipleProspect: boolean): FormlyFieldConfig {
    return {
      props: {
        label: 'revenues',
        formTitle: this._i18nService._('Prospect_Revenues_Simulation_Form_Title'),
        icon: 'Briefcase',
      },
      key: 'revenues',
      expressions: {
        'props.subTitle': (field: FormlyFieldConfig) =>
          this._i18nService._('Prospect_Revenues_Simulation_Form_Subtitle', [
            formatCurrency(this.calculateAmountsSum(field.model), this.locale, '€', null, '2.0-2'),
          ]),
      },
      fieldGroup: [
        {
          fieldGroup: [
            this.getSimulationAmountField({
              id: RevenueTypeEnum.SALARY,
              type: RevenueTypeEnum.SALARY,
              isCharge: false,
              props: {
                tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Before_Taxes'),
              },
              className: 'flex-1',
            }),
            this.getSimulationAmountField({
              id: RevenueTypeEnum.PRIME,
              type: RevenueTypeEnum.PRIME,
              isCharge: false,
              props: {
                tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Before_Taxes'),
              },
              className: 'flex-1',
            }),
          ],
          fieldGroupClassName: 'flex gap-4',
        },
        isMultipleProspect
          ? {
              fieldGroup: [
                this.getSimulationAmountField({
                  id: RevenueTypeEnum.SALARY_ACQ2,
                  type: RevenueTypeEnum.SALARY_ACQ2,
                  isCharge: false,
                  props: {
                    tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Before_Taxes'),
                  },
                  className: 'flex-1',
                }),
                this.getSimulationAmountField({
                  id: RevenueTypeEnum.PRIME_ACQ2,
                  type: RevenueTypeEnum.PRIME_ACQ2,
                  isCharge: false,
                  props: {
                    tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Before_Taxes'),
                  },
                  className: 'flex-1',
                }),
              ],
              fieldGroupClassName: 'flex gap-4',
            }
          : null,
        this.getSimulationAmountField({
          id: RevenueTypeEnum.RENT,
          type: RevenueTypeEnum.RENT,
          isCharge: false,
          props: {
            tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Before_Taxes'),
          },
        }),
        {
          fieldGroup: [
            this.getSimulationAmountField({
              id: RevenueTypeEnum.OTHER_MONTHLY,
              type: RevenueTypeEnum.OTHER_MONTHLY,
              isCharge: false,
              props: {
                tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Other_Monthly'),
              },
              className: 'flex-1',
            }),
            this.getSimulationAmountField({
              id: RevenueTypeEnum.OTHER_ANNUALY,
              type: RevenueTypeEnum.OTHER_ANNUALY,
              isCharge: false,
              className: 'flex-1',
            }),
          ],
          fieldGroupClassName: 'flex gap-4',
        },
      ],
      className: 'w-full',
    };
  }

  getChargesSimulation(): FormlyFieldConfig {
    const calculateSubTitle = (field: FormlyFieldConfig) =>
      this._i18nService._('Prospect_Charges_Simulation_Form_Subtitle', [
        formatCurrency(this.calculateAmountsSum(field.model), this.locale, '€', null, '2.0-2'),
      ]);

    return {
      props: {
        label: 'charges',
        formTitle: this._i18nService._('Prospect_Charges_Simulation_Form_Title'),
        icon: 'CreditCard',
      },
      key: 'charges',
      expressions: { 'props.subTitle': calculateSubTitle },
      fieldGroup: [
        {
          key: 'is_owner',
          type: 'radio',
          props: {
            label: this._i18nService._('Prospect_Charges_Simulation_Is_Owner'),
            options: [
              {
                label: this._i18nService._('Prospect_Charges_Simulation_Owner'),
                value: true,
              },
              {
                label: this._i18nService._('Prospect_Charges_Simulation_Renter'),
                value: false,
              },
            ],
          },
          className: 'mb-8 inline-radio',
        },
        {
          key: 'has_estate_credit',
          type: 'radio',
          props: {
            label: this._i18nService._('Prospect_Charges_Simulation_Credit_On_Owner'),
            options: [
              {
                label: this._i18nService._('Prospect_Charges_Simulation_Credit_On_Owner_True'),
                value: true,
              },
              {
                label: this._i18nService._('Prospect_Charges_Simulation_Credit_On_Owner_False'),
                value: false,
              },
            ],
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => field.parent.model.is_owner !== true,
          },
          className: 'mb-8 inline-radio',
        },
        this.getSimulationAmountField({
          id: ChargeTypeEnum.ESTATE_LOAN,
          type: ChargeTypeEnum.ESTATE_LOAN,
          isCharge: true,
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              field.parent.model.has_estate_credit !== true && field.parent.model.has_estate_credit !== false,
            className: (field: FormlyFieldConfig) => (field.parent.model.has_estate_credit === false ? 'hidden' : 'mb-12'),
          },
          hooks: {
            onInit: (f) => {
              return f.form.valueChanges.pipe(
                startWith(f.form.value),
                tap((value) => {
                  if (value.has_estate_credit === false && value[f.key as string] && value[f.key as string].amount !== 0) {
                    setTimeout(() => {
                      f.formControl.setValue({ ...value[f.key as string], amount: 0 });
                    }, 1);
                  }
                }),
              );
            },
          },
        }),
        this.getSimulationAmountField({
          id: ChargeTypeEnum.RENT,
          type: ChargeTypeEnum.RENT,
          isCharge: true,
          expressions: {
            hide: (field: FormlyFieldConfig) => field.parent.model.is_owner !== false,
          },
          className: 'mb-12',
        }),
        {
          key: 'other_charges',
          type: 'repeat',
          props: {
            icon: 'Plus',
            addLabel: this._i18nService._('Prospect_Simulation_Charges_Add'),
            removeLabel: this._i18nService._('Prospect_Simulation_Charges_Remove'),
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'id',
                className: 'hidden',
                expressions: {
                  defaultValue: () => uuidV4(),
                },
              },
              {
                key: 'chargeTypeCode',
                type: 'select',
                props: {
                  label: this._i18nService._(`Prospect_Simulation_Charges_Loan_Type`),
                  options: [
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.OTHER_ESTATE_LOAN}`),
                      value: ChargeTypeEnum.OTHER_ESTATE_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.CONSUMER_LOAN}`),
                      value: ChargeTypeEnum.CONSUMER_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.REVOLVING_LOAN}`),
                      value: ChargeTypeEnum.REVOLVING_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.STUDENT_LOAN}`),
                      value: ChargeTypeEnum.STUDENT_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.VEHICLE_LOAN}`),
                      value: ChargeTypeEnum.VEHICLE_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.LOA_LOAN}`),
                      value: ChargeTypeEnum.LOA_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.LDD_LOAN}`),
                      value: ChargeTypeEnum.LDD_LOAN,
                    },
                    {
                      label: this._i18nService._(`Prospect_Simulation_Charges_${ChargeTypeEnum.OTHER_LOAN}`),
                      value: ChargeTypeEnum.OTHER_LOAN,
                    },
                  ],
                  required: true,
                },
              },
              {
                key: 'amount',
                type: 'number',
                props: {
                  label: this._i18nService._(`Prospect_Simulation_Charges_Monthly_Cost`),
                  required: true,
                },
                validators: {
                  validation: [Validators.min(0)],
                },
              },
            ],
            fieldGroupClassName: 'w-full flex gap-4',
          },
          className: 'w-full mb-8',
        },
        {
          fieldGroup: [
            this.getSimulationAmountField({
              id: ChargeTypeEnum.OTHER_MONTHLY,
              type: ChargeTypeEnum.OTHER_MONTHLY,
              isCharge: true,
              props: {
                tooltip: this._i18nService._('Prospect_Simulation_Tooltip_Other_Monthly_Charges'),
              },
              className: 'flex-1',
            }),
            this.getSimulationAmountField({
              id: ChargeTypeEnum.OTHER_ANNUALY,
              type: ChargeTypeEnum.OTHER_ANNUALY,
              isCharge: true,
              className: 'flex-1',
            }),
          ],
          fieldGroupClassName: 'flex gap-4',
        },
      ],
      className: 'w-full',
    };
  }

  getLoanSimulation(): FormlyFieldConfig {
    return {
      props: {
        label: 'properties',
        formTitle: this._i18nService._('Prospect_Simulation_Loan_Properties'),
        icon: 'Cog8Tooth',
      },
      expressions: {
        'props.subTitle': (field: FormlyFieldConfig) =>
          this._i18nService._('Prospect_Simulation_Loan_Properties_Subtitles', [
            formatCurrency(field.model['personalFunds'] || 0, this.locale, '€', null, '2.0-2'),
          ]),
      },
      fieldGroup: [
        {
          fieldGroup: [
            {
              key: 'loanRate',
              type: 'number',
              props: {
                label: this._i18nService._(`Prospect_Simulation_Loan_Loan_Rate`),
                required: true,
                suffix: ' %',
                invisibleField: true,
                maxFractionDigits: 2,
              },
              validators: {
                validation: [Validators.min(0)],
              },
              className: 'flex-1',
            },
            {
              key: 'insuranceRate',
              type: 'number',
              props: {
                label: this._i18nService._(`Prospect_Simulation_Loan_Insurance_Rate`),
                required: true,
                suffix: ' %',
                invisibleField: true,
                maxFractionDigits: 2,
              },
              validators: {
                validation: [Validators.min(0)],
              },
              className: 'flex-1',
            },
          ],
          fieldGroupClassName: 'flex gap-4 mb-8',
        },
        {
          fieldGroup: [
            {
              key: 'loanDuration',
              type: 'number',
              props: {
                label: this._i18nService._(`Prospect_Simulation_Loan_Loan_Duration`),
                required: true,
                suffix: ' ans',
              },
              validators: {
                validation: [Validators.min(0)],
              },
              className: 'flex-1',
            },
            {
              key: 'personalFunds',
              type: 'number',
              props: {
                label: this._i18nService._(`Prospect_Simulation_Loan_Funds`),
                required: true,
                suffix: '  €',
              },
              validators: {
                validation: [Validators.min(0)],
              },
              className: 'flex-1',
            },
          ],
          fieldGroupClassName: 'flex gap-4',
        },
      ],
      className: 'w-full',
    };
  }

  //**************Utils*********************/

  shouldContinueIfNoDuplicate(form: UntypedFormGroup): Observable<boolean> {
    const dossierProspectData = DossierProspectMapper.fromForm(form.value);
    return this._dossierProspect.checkDuplicate(dossierProspectData).pipe(map((hasDuplicate) => !hasDuplicate));
  }

  calculateAmountsSum(form: UntypedFormGroup) {
    let sum = 0;
    for (const key in form) {
      if (typeof form[key] === 'boolean') {
        continue;
      }
      if (form[key]?.amount && form[key].amount >= 0) {
        if (key.indexOf('ANNUALY') >= 0) {
          sum += form[key].amount / 12;
        } else {
          sum += form[key].amount;
        }
        continue;
      }
      if (form[key]?.length) {
        form[key].forEach((charge) => {
          if (charge?.amount && charge.amount >= 0) {
            sum += charge.amount;
          }
        });
      }
    }
    return Math.round(sum);
  }

  getSimulationAmountField(data: SimulationAmountInputModel): FormlyFieldConfig {
    return {
      props: {
        label: `${data.type}`,
      },
      key: data.type,
      expressions: data.expressions,
      fieldGroup: [
        {
          key: data.isCharge ? 'chargeTypeCode' : 'revenueTypeCode',
          defaultValue: data.type,
          className: 'mb-0',
        },
        {
          key: 'amount',
          type: 'number',
          props: {
            label: this._i18nService._(`Prospect_Simulation_${data.isCharge ? 'Charges_' : 'Revenues_'}${data.type}`),
            mode: 'currency',
            currency: 'EUR',
            maxFractionDigits: 2,
            minFractionDigits: 2,
            ...data.props,
          },
          validators: {
            validation: [Validators.min(0)],
          },
          className: 'w-full',
        },
        data.isCharge
          ? {
              key: 'id',
              defaultValue: uuidV4(),
              className: 'mb-0',
            }
          : null,
      ],
      className: `mb-8${data.className ? ' ' + data.className : ''}`,
      hooks: data.hooks,
    };
  }
}

export interface SimulationAmountInputModel {
  id: string;
  type: RevenueTypeEnum | ChargeTypeEnum;
  isCharge: boolean;
  expressions?: {
    [property: string]:
      | string
      | Observable<any>
      | ((field: FormlyFieldConfig<FormlyFieldProps & { [additionalProperties: string]: any }>) => any);
  };
  props?: FormlyFieldProps & { [additionalProperties: string]: any };
  className?: string;
  hooks?: FormlyHookConfig;
}
