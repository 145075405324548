import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldType, FormlyConfig } from '@ngx-formly/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { NgIf } from '@angular/common';

import { CityResponse } from '../../../utils/models/CityResponse';
import { CityService } from '../../../utils/services/city.service';
import { CitiesLocationResponse } from '../../../utils/models/CitiesLocationResponse';

@Component({
  selector: 'app-formly-localization',
  templateUrl: './formly-localization.component.html',
  styleUrls: ['./formly-localization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, InputTextModule, ReactiveFormsModule, DropdownModule],
})
export class FormlyLocalizationComponent extends FieldType implements OnInit, OnDestroy {
  localizationForm: UntypedFormGroup = new UntypedFormGroup({
    postalCode: new UntypedFormControl(),
    city: new UntypedFormControl(),
  });

  cityList: CityResponse[];
  selectedCity: CityResponse;

  isInternalChange = false;

  private $destroy: Subject<void> = new Subject<void>();

  constructor(
    private readonly cityService: CityService,
    private readonly detectorRef: ChangeDetectorRef,
    private config: FormlyConfig,
  ) {
    super();
  }

  ngOnInit(): void {
    this.localizationForm
      .get('postalCode')
      .valueChanges.pipe(takeUntil(this.$destroy), debounceTime(500))
      .subscribe((postalCode) => {
        this.handlePostalCodeChange(postalCode);
      });

    this.localizationForm
      .get('city')
      .valueChanges.pipe(takeUntil(this.$destroy))
      .subscribe(() => {
        this.field.formControl.setValue(this.localizationForm.value);
      });

    if (this.field.formControl.value) {
      this.localizationForm.get('postalCode').setValue(this.field.formControl.value.postalCode);
      this.localizationForm.get('city').setValue(this.field.formControl.value.city);
    }

    this.field.formControl.addValidators(() => {
      return this.localizationForm.get('postalCode').valid && this.localizationForm.get('city').valid
        ? null
        : { invalidLocalization: true };
    });

    this.detectorRef.detectChanges();
  }

  private handlePostalCodeChange(postalCode: string): void {
    this.isInternalChange = true;
    this.field.formControl.setValue(this.localizationForm.value);
    if (postalCode?.length >= 2 && !this.field.props?.city?.isForeignCountry) {
      this.fetchCities(postalCode);
    }
  }

  private fetchCities(postalCode: string): void {
    const data = { filter: postalCode, typeAccepted: {}, language: 'fr-FR' };
    this.cityService.searchLocalisation(data).subscribe((localizationResponse) => this.processLocalizationResponse(localizationResponse));
  }

  private processLocalizationResponse(localizationResponse: CitiesLocationResponse): void {
    const cityControl = this.localizationForm.get('city');

    const cityExistInList = localizationResponse.cities.find((city) => city.label === cityControl.value);

    if (!cityExistInList && cityControl.value) {
      cityControl.setValue(null);
    }

    if (localizationResponse.cities.length === 1) {
      this.selectedCity = localizationResponse.cities[0];
      cityControl.setValue(this.selectedCity.label, { emitEvent: false });
    }

    this.cityList = localizationResponse.cities;
    this.setFormlyFormValue();
    this.detectorRef.detectChanges();
    this.isInternalChange = false;
  }

  ngOnDestroy(): void {
    this.$destroy.next();
  }

  getErrorMessage(fieldForm: UntypedFormControl) {
    for (const error in fieldForm.errors) {
      // eslint-disable-next-line no-prototype-builtins
      if (fieldForm.errors.hasOwnProperty(error)) {
        const message = this.config.getValidatorMessage(error);

        if (typeof message === 'function') {
          return message(fieldForm.errors[error], this.field);
        }

        return message;
      }
    }

    return undefined;
  }

  public setFormlyFormValue() {
    if (this.localizationForm.get('city').valid && this.localizationForm.get('postalCode').valid) {
      this.field.formControl.setValue({
        postalCode: this.localizationForm.get('postalCode').value,
        city: this.localizationForm.get('city').value,
      });
    } else {
      this.field.formControl.setValue(undefined);
    }
  }
}
