export const signature = {
  txt_back_to_signature_file: 'Retour au dossier',
  txt_signature_reload_data: 'Actualiser les données',

  txt_stepper_buyer_header: 'Acquéreur(s)',
  txt_stepper_buyer_type: 'Type d’acquéreur',
  txt_stepper_buyer_type_title: 'Le dossier concerne...',
  txt_stepper_buyer_1: 'Acquéreur N° 1',
  txt_stepper_buyer_2: 'Acquéreur N° 2',
  txt_stepper_signatory_manager: 'Gérant signataire',
  txt_stepper_buyer_personnal_information: 'Informations personnelles',
  txt_stepper_buyer_contact_information: 'Informations de contact',
  txt_stepper_buyer_situation: 'Situation pro et familiale',
  txt_stepper_buyer_society_information: 'Informations société',
  txt_stepper_buyer_society_manager: 'Informations personnelles',

  txt_stepper_lot_header: 'Lot réservé',
  txt_stepper_lot_habitation: 'Logement',
  txt_stepper_lot_price: 'Prix et fiscalité',

  txt_stepper_fnct_header: 'Financement et notaire',
  txt_stepper_fnct_funding: 'Financement',
  txt_stepper_fnct_notaire: 'Notaire',

  txt_stepper_next: 'Suivant',
  txt_stepper_previous: 'Précédent',
  txt_stepper_last: 'Terminer',

  txt_signature_form_context_lot_detail_housing_title: 'Détail du logement',
  txt_signature_form_context_lot_plan_button: 'Plan du lot',

  txt_signature_form_context_lot_price_initial: 'Prix de vente initial TTC',
  NOMINAL_TAXATION_POURCENTAGE: 'TVA: 20%',
  ANRU_POURCENTAGE: 'TVA: 5.5%',
  PINEL_POURCENTAGE: 'TVA: 20%',
  'PINEL PLUS_POURCENTAGE': 'TVA: 20%',
  PLS_POURCENTAGE: 'TVA: 20%',
  'LMNP/LMP_POURCENTAGE': 'TVA: 20%',
  MALRAUX_POURCENTAGE: 'TVA: 20%',
  'MONUMENTS HISTORIQUES_POURCENTAGE': 'TVA: 20%',
  'DEFICIT FONCIER_POURCENTAGE': 'TVA: 20%',
  NOMINAL_TAXATION: 'TVA Nominale',
  'LMNP NON GERE_POURCENTAGE': 'TVA: 20%',
  'NUE PROPRIETE_POURCENTAGE': 'TVA: 10%',
  ANRU: 'ANRU',
  PINEL: 'PINEL',
  PLS: 'PLS',
  LMNP: 'LMNP/LMP',
  MALRAUX: 'MALRAUX',
  txt_signature_form_context_lot_price_initial_sub: '(annexes incluses)',
  txt_signature_form_context_lot_price_final: 'Prix de vente final TTC',
  txt_signature_form_context_lot_price_final_sub: '(avec remises)',
  txt_signature_dashboard_title: 'Signature du lot',
  txt_downloadLiasseContreSignee: 'Télécharger la liasse contre signée',
  txt_see_cancel_reason: 'Consulter le motif d’annulation',
  txt_popin_see_cancel_reason_title: 'Motif d’annulation du dossier de signature',
  txt_popin_see_cancel_reason_reason: 'Motif : {0}',
  txt_popin_see_cancel_reason_close: 'Fermer',

  txt_file_drop_form_accepted_files: 'Documents acceptés',
  txt_file_drop_form_max_size: 'Taille maximum',
  txt_file_drop_form_mb: 'Mb',
  txt_commentaire_daskboard: 'Exemple : SCI en cours de création, annexes modifiées, négociation remise promoteur...',

  txt_special_offer_subLabel: 'Une offre spéciale promoteur existe sur ce lot',
  txt_special_offer_tagLabel: 'offre spéciale',

  // Dashboard
  txt_return: 'Retour liste des signatures',
  txt_return_dossier_signature: 'Retour au dossier',
  txt_button_preview: 'Prévisualiser et valider',

  // Document
  txt_document_add: 'Ajouter un document',
  txt_document_add_spe: 'Ajouts spécifiques',
  txt_format_invalid: "Ce format de fichier n'est pas accepté",

  // Documents modal
  Title_documents_modal: 'Ajout de documents supplémentaires',
  txt_documents_modal_description: 'Ajoutez des documents complémentaires au dossier. Contacter votre RPV si besoin.',
  txt_documents_modal_format: 'PDF, PNG, JPG, JPEG',
  txt_documents_modal_utils: 'Documents utiles >',
  txt_documents_modal_gatanties: 'Garanties locatives >',

  //Signature cancel modal
  txt_signature_cancel_modal_title: 'Vous allez annuler le dossier de signature en cours',
  txt_signature_cancel_modal_title_dossier: 'Annuler le dossier de signature',
  txt_signature_cancel_modal_title_reject: 'Vous allez créer une nouvelle version du dossier de signature',
  txt_signature_cancel_modal_comment_label: 'Commentaire (sera transmis aux signataires)',
  txt_signature_cancel_modal_comment_label_dossier: 'Motif d’annulation',
  txt_signature_cancel_modal_comment_label_reject: 'Commentaire',
  txt_signature_cancel_modal_text:
    'Pour modifier ce dossier de signature, vous allez annuler l’envoi du dossier actuel. Vous ne perdrez pas les informations déjà saisies et pourrez remettre en signature après modification.',
  txt_signature_cancel_modal_text_reject:
    'Pour modifier ce dossier de signature, vous allez créer une nouvelle version du dossier actuel. Vous ne perdrez pas les informations déjà saisies et pourrez remettre en signature après modification.',
  txt_signature_cancel_modal_text_dossier:
    "Êtes-vous sûr de vouloir annuler ce dossier de signature ? Cette action est irréversible. Si vous souhaitez modifier le dossier de signature, veuillez choisir l'option modifier",
  txt_signature_cancel_modal_confirm_cancel: 'Confirmer et annuler',
  txt_signature_cancel_modal_cancel_cancel: 'Fermer et annuler',
  txt_signature_cancel_modal_confirm_cancel_dossier: 'Oui',
  txt_signature_cancel_modal_cancel_cancel_dossier: 'Non',
  txt_signature_cancel_modal_dossier_success: 'Le dossier a bien été annulé',
  // Categories
  txt_remained_fields: 'champs à remplir',

  // Form Header
  txt_action: 'Actions',

  // Comment
  txt_placeholder_comment: 'Commentaire pour Valorissimo',

  // Side bar
  txt_lot_numero: 'Lot n°',
  txt_inital_price: 'Prix de vente initial',
  txt_delivery: 'Livraison',
  txt_living_area: 'surface habitable',
  txt_LOGGIA: 'loggia',
  txt_GARDEN: 'surface du jardin',
  txt_BALCONY: 'surface du balcon',
  txt_TERRACE: 'surface du terrace',

  // Synthesis
  txt_return_progress: 'Retour suivi du dossier',
  txt_synthesis_modify: 'Modifier',
  txt_synthesis_validate: 'Valider et continuer',
  txt_synthesis_pdf_load: 'Votre contrat de réservation est en cours de création, merci de patienter...',
  txt_synthesis_pdf_error_1: 'Une erreur est survenue lors de la génération du contrat de réservation.',
  txt_synthesis_pdf_error_2:
    "Veuillez réessayer en cliquant sur le bouton “Modifier” pour revenir à l'étape précédente puis cliquer à nouveau sur “Prévisualiser et valider“.",
  txt_synthesis_pdf_error_3: 'Si le problème persiste, veuillez contacter votre interlocuteur Valorissimo.',

  // Choose Signature Type
  txt_signature_type_title: 'Envoi du dossier imminent !',
  txt_title_remote: 'À distance',
  txt_title_physical: 'Avec mon client',
  txt_title_paper: 'Contrat papier',
  txt_signature_type_not_available: 'Bientôt disponible',
  txt_description_remote: 'Envoyez ce dossier de signature par mail afin que votre client signe en ligne, de chez lui.',
  txt_description_physical_1: 'Vous êtes actuellement avec votre client.',
  txt_description_physical_2: 'Signez en ligne maintenant avec lui, la signature se fera sur votre écran.',
  txt_description_paper: 'Téléchargez le contrat papier afin de l’imprimer pour votre client. Classique, mais beaucoup plus long !',
  txt_signature_type_cancel: 'Annuler',
  txt_signature_type_confirm_remote: 'Envoyer le dossier',
  txt_signature_type_confirm_physical: 'Signer avec mon client',
  txt_signature_type_confirm_paper: 'Valider et télécharger le dossier',
  txt_signature_type_in_progress_info: 'Dossier de signature en cours d’envoi. L’envoi peut prendre quelques minutes',
  txt_signature_type_success: 'Dossier de signature envoyé avec succès',
  txt_signature_type_error:
    'Une erreur s’est produite lors de l’envoi du dossier de signature. Veuillez ré-essayer ou contacter votre interlocuteur Valorissimo',

  //Progress
  txt_signature_progress_remote: 'Signature en ligne',
  txt_signature_progress_paper: 'Signature papier',
  txt_signature_progress_error_1: "Les informations de suivi n'ont pu être récupérées",
  txt_signature_progress_error_2: 'Vous pouvez recharger la page pour relancer le chargement.',
  txt_signature_progress_error_3: 'Si le problème persiste, veuillez contacter votre interlocuteur Valorissimo.',
  txt_signature_progress_send: 'Envoi en signature',
  txt_signature_progress_send_failure: "Echec de l'envoi",
  txt_signature_progress_send_date: 'Envoyé le {0}',
  txt_signature_progress_send_pending: 'En cours (peut prendre quelques minutes) ',
  txt_signature_progress_data: '{0}',
  txt_signature_progress_buyer: 'Signature acquéreur',
  txt_signature_progress_buyer_paper_untracked:
    'Cette signature n’étant pas dématérialisée,<br> nous ne pouvons pas suivre son étant d’avancement',
  txt_signature_progress_buyer_remaining_time: '{0} jour restant',
  txt_signature_progress_buyer_remaining_time_Plural: '{0} jours restants',
  txt_signature_progress_info: 'En savoir plus',
  txt_signature_progress_buyer_remind: 'Relancer le client',
  txt_signature_progress_modify: 'Modifier',
  txt_signature_progress_download_proof: 'Liasse contre signée',
  txt_signature_progress_valo_valid: 'Valider le contrat',
  txt_signature_progress_developer_valid: 'Voir et valider',
  txt_signature_progress_expired: 'Expiré',
  txt_signature_progress_show_files: 'Voir le dossier',

  // Event label
  txt_signature_progress_buyer_signed: 'Signé le {0}',
  txt_signature_progress_buyer_declined: 'Refusé le {0}',
  txt_signature_progress_buyer_pending: 'En cours',
  txt_signature_progress_developer: 'Contre-signature promoteur',
  txt_signature_progress_developer_declined: 'Refusé le {0}',
  txt_signature_progress_developer_signed: 'Contre-signé le {0}',
  txt_signature_progress_developer_pending: 'En attente',

  //prefill popin
  prefill_title: 'Reprendre les informations de l’acquéreur',
  prefill_text: "Sélectionnez les données à dupliquer de l'acquéreur 1 pour l'acquéreur 2.",
  prefill_acq_2_lastname: 'Nom de famille',
  prefill_acq_2_address: 'Adresse',
  prefill_postalCode: 'Code Postal',
  prefill_city: 'Ville',
  prefill_acq_2_country: 'Pays',
  prefill_acq_2_nationality: 'Nationalité',
  prefill_acq_2_family_situation: 'Situation familiale',
  prefill_acq_2_wedding_pacs_city: 'Lieu du mariage',
  prefill_acq_2_wedding_pacs_date: 'Date du mariage',
  prefill_acq_2_matrimonial_regime: 'Régime matrimonial / PACS',
  prefill_validate: 'Préremplir',
  prefill_button: 'Préremplir',

  //update popin
  update_signature_dossier: 'Actualiser les données du dossier',
  update_signature_dossier_text:
    'Vous allez actualiser le dossier de signature à partir des données les plus récentes du lot.<br>Notez que vous ne perdrez pas les informations déjà saisies via le formulaire.',
  update_signature_dossier_cancel: 'Annuler',
  update_signature_dossier_validate: 'Confirmer et actualiser',
  update_signature_dossier_success: 'Le dossier a été actualisé',

  err_docusign_call: "Une erreur a eu lieu durant l'appel à docusign.",
};
