import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { isNil } from 'lodash';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { FormlyNavigationService } from '../formly-navigation-service';

import { MyStepComponent } from '../../form/stepper/stepper/my-step/my-step.component';
import { I18nService } from '../../../utils/services/i18n.service';
import { StepperComponent } from '../../form/stepper/stepper/stepper.component';
import { IconComponent } from '../../standalone/icon/icon.component';

/**
 * Stepper props:
 * isHead: Big title on sidebar
 * subHead: small title on sidebar
 * headId: ref to an isHead element id
 * stepActionOnFieldKey: allow to change step with the field key
 * formTitle: header
 */
@Component({
  selector: 'app-formly-stepper',
  templateUrl: './formly-stepper.component.html',
  styleUrls: ['./formly-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StepperComponent, NgFor, NgIf, MyStepComponent, CdkStepperModule, NgClass, IconComponent, FormlyModule],
})
export class FormlyStepperComponent extends FieldType<FieldTypeConfig> implements AfterViewInit, OnDestroy {
  @ViewChild('stepper') stepper: StepperComponent;
  public selectedStepIndex: number;
  public selectedStep: MyStepComponent;
  private readonly $destroy: Subject<void> = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    public readonly i18nService: I18nService,
    private readonly detectorRef: ChangeDetectorRef,
    private readonly formlyNavigationService: FormlyNavigationService,
  ) {
    super();

    this.formlyNavigationService.nextPageSubject.subscribe(() => {
      if (!this.stepper) {
        return;
      }
      this.next();
    });

    this.formlyNavigationService.previousPageSubject.subscribe(() => {
      if (!this.stepper) {
        return;
      }
      this.back();
    });
  }

  ngAfterViewInit() {
    this.stepper.fields.forEach((field) => {
      if (field.props['hideStepAction']) {
        const step = field.fieldGroup.find((f) => f.key === field.props['stepActionOnFieldKey']);
        step.formControl.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
          this.next();
        });
      }
    });
    this.selectedStep = this.route.snapshot.queryParams['activeStep'] || this.stepper.selected || this.selectedStep;
    this.selectedStepIndex = this.getActiveStepIndex(this.selectedStep);
    this.formlyNavigationService.changePage(this.selectedStepIndex);
    this.detectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field.hide) {
      return true;
    }

    if (field.props.disabled && this.model[field.key.toString()] !== null && this.model[field.key.toString()] !== undefined) {
      return true;
    }

    if (field.fieldGroup?.length) {
      return field.fieldGroup.every((f) => this.isValid(f));
    }

    if (field.key) {
      return !!field?.formControl?.valid;
    } else {
      return true;
    }
  }

  onPageChanged(stepper: StepperComponent) {
    this.updateSelectedStep(stepper.selectedStep);
    this.formlyNavigationService.changePage(stepper.selectedIndex);
  }

  private updateSelectedStep(step: MyStepComponent): void {
    this.selectedStep = step;
  }

  private async shouldContinue(): Promise<boolean> {
    if (this.selectedStep.field.props.beforeNext) {
      return !isNil(await lastValueFrom(this.selectedStep.field.props.beforeNext(this.form)));
    }
    return true;
  }

  private handleHeadStep(): void {
    if (this.selectedStep.field.props['isHead']) {
      const index = this.getFieldIndex(this.selectedStep);
      this.toggleSubStep(index);
      this.next();
    }
  }

  private handleSubHeadStep(): void {
    if (this.selectedStep.field.props['isSubHead']) {
      this.next();
    }
  }

  async next() {
    if (!this.stepper) {
      return;
    }
    this.stepper.next();
    this.updateSelectedStep(this.stepper.selected as MyStepComponent);

    const page = this.getFieldIndex(this.selectedStep);
    this.formlyNavigationService.changePage(page);

    if (!(await this.shouldContinue())) {
      return;
    }

    this.handleHeadStep();
    this.handleSubHeadStep();
    this.detectorRef.detectChanges();
  }

  finish() {
    this.formlyNavigationService.submit();
  }

  back() {
    this.stepper.previous();
    this.selectedStep = this.stepper.selected as MyStepComponent;
    if (this.selectedStep.field.props['isHead']) {
      this.back();
    }
    if (this.selectedStep.field.props['isSubHead']) {
      this.back();
    }
    this.togglePreviousStep(this.selectedStep);
    this.detectorRef.detectChanges();
  }

  private getActiveStepIndex(activeStep) {
    if (activeStep) {
      const stepHeadIndex = this.getHeadStepIndex(activeStep);
      const headIndex = this.getHeadFieldIndex(activeStep);
      this.toggleSubStep(headIndex);
      return stepHeadIndex + 1;
    }
    this.toggleSubStep(0);
    return 1;
  }

  private getHeadFieldIndex(activeStep) {
    let headIndex = 0;
    this.field.fieldGroup.forEach((f, i) => {
      if (f.props['id'] === activeStep) {
        headIndex = i;
      }
    });
    return headIndex;
  }

  private getHeadStepIndex(activeStep) {
    let stepHeadIndex = 0;
    this.stepper.steps.forEach((step: MyStepComponent, i) => {
      if (step.field.props['id'] === activeStep) {
        stepHeadIndex = i;
      }
    });
    return stepHeadIndex;
  }

  private getFieldIndex(selected: MyStepComponent) {
    let index = 0;
    this.field.fieldGroup.forEach((item, i) => {
      if (item.id === selected.field.id) {
        index = i;
      }
    });
    return index;
  }

  private togglePreviousStep(selected: MyStepComponent) {
    selected.field.props['open'] = true;
    const index = this.getFieldIndex(selected);
    for (let i = index; i >= 0; i--) {
      const f = this.field.fieldGroup[i];
      if (f.props['isHead']) {
        f.props['open'] = true;
      } else {
        f.props['expand'] = true;
      }
    }
  }

  private toggleSubStep(index: number) {
    this.field.fieldGroup[index].props['open'] = true;
    for (let i = index + 1; i < this.field.fieldGroup.length; i++) {
      const field = this.field.fieldGroup[i];
      if (!field.props['isHead']) {
        field.props['expand'] = true;
      } else {
        break;
      }
    }
  }
}
