import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

import { I18nService } from '../../services/i18n.service';
import { PhoneNumberCountryCodeMapperService } from '../../services/phone-number/phone-number-code-mapper.service';
import { getLengthValidationError } from '../../services/utils/validator-utils';
import { phoneNumberValidators } from '../../validators/phoneNumber-validator.directive';

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-phone-number-information',
  templateUrl: './phone-number-information.component.html',
  styleUrls: ['./phone-number-information.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgxMatIntlTelInputComponent,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class PhoneNumberInformationComponent implements OnInit {
  /**
   * parentForm input
   *
   * @type {FormGroup}
   * @memberof PhoneNumberInformationComponent
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * isRequired input
   *
   * @type {boolean}
   * @memberof PhoneNumberInformationComponent
   */
  @Input() isRequired: boolean;
  /**
   * fieldname input
   *
   * @type {string}
   * @memberof PhoneNumberInformationComponent
   */
  @Input() fieldName: string;
  /**
   * placeholder input
   *
   * @type {string}
   * @memberof PhoneNumberInformationComponent
   */
  @Input() placeholder: string;
  @ViewChild('ipn') phoneNumberComponent: NgxMatIntlTelInputComponent;
  public phoneControl: UntypedFormControl;
  isPhoneNumberValid = true;
  focus: boolean;

  public errorMessage: string;

  /**
   * Creates an instance of PhoneNumberInformationComponent.
   * @param {I18nService} i18nService
   * @memberof PhoneNumberInformationComponent
   */
  constructor(
    public i18nService: I18nService,
    private readonly phoneNumberCountryCodeMapperService: PhoneNumberCountryCodeMapperService,
  ) {
    // By default values, if inputs not filled
    this.isRequired = false;
    this.fieldName = 'phone';
    this.placeholder = this.i18nService._('Txt_Placeholder_Phone');
  }

  private _isSubmit: boolean;

  get isSubmit(): boolean {
    return this._isSubmit;
  }

  /**
   * isSubmitInput
   *
   * @type {boolean}
   * @memberof PhoneNumberInformationComponent
   */
  @Input() set isSubmit(submit: boolean) {
    this._isSubmit = submit;

    if (this._isSubmit) {
      // Used to check only when isSubmit setted
      this.focusOut(undefined);
    }
  }

  ngOnInit(): void {
    this.parentForm.addControl(this.fieldName, new UntypedFormControl('', []));
    this.parentForm.addControl('mobilePhoneCountryCode', new UntypedFormControl(''));
    this.parentForm.addControl('mobilePhoneWithoutCountryCode', new UntypedFormControl(''));
    this.phoneControl = this.parentForm.controls[this.fieldName] as UntypedFormControl;
    // Add asterix to placeholder if it is a required phone number
    if (this.isRequired) {
      this.placeholder = `${this.placeholder} *`;
    }
  }

  private validatePhoneNumber(): void {
    const value = this.getPhoneNumber();
    if (this.isEmptyAndRequired(value)) {
      this.errorMessage = 'Error_Field_RequiredPhoneNumber';
      return;
    }
    const countryCode = this.getCountryCode();
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);
      this.validateParsedNumber(parsedNumber);
    } catch (error) {
      this.errorMessage = value.length === 1 ? 'TOO_SHORT' : 'INVALID_NUMBER_FORMAT'; // PhoneUtil can't parse one digit numbers
    }
  }

  private getPhoneNumber(): string {
    return this.phoneNumberComponent.phoneNumber ? this.phoneNumberComponent.phoneNumber : this.phoneNumberComponent.value;
  }

  private isEmptyAndRequired(value: string): boolean {
    return (value === undefined || value === '') && this.phoneControl?.errors?.required;
  }

  private getCountryCode(): string {
    const country = this.phoneNumberComponent.selectedCountry.iso2;
    return this.phoneNumberCountryCodeMapperService.getCountryCode(country);
  }

  private validateParsedNumber(parsedNumber: PhoneNumber): void {
    const validNumberLength = phoneUtil.isPossibleNumberWithReason(parsedNumber);

    if (validNumberLength) {
      const lengthError = getLengthValidationError(validNumberLength);
      this.errorMessage = Object.keys(lengthError)[0];
    } else {
      const isValidNumber = phoneUtil.isValidNumber(parsedNumber);
      this.errorMessage = isValidNumber ? '' : 'INVALID_NUMBER_FORMAT';
    }
  }

  /**
   * focusOut method
   *
   * @memberof PhoneNumberInformationComponent
   */
  focusOut(event): void {
    let selectedCountryDialCode = this.phoneNumberComponent.selectedCountry.dialCode;
    // Treatment only if we focus out the input (not the flag selection)
    if (event?.target?.nodeName === 'INPUT') {
      // Get value without space
      const entryValue = this.phoneControl.value;
      // Remove value if default filling
      if (entryValue === '+33') {
        // This to fix a bug of ngx international phone component
        this.phoneControl.setValue('+3');
        this.phoneControl.setValue('');
      }
    }
    this.focus = false;

    this.isPhoneNumberValid =
      // Validate value in input (if not null)
      this.phoneControl && this.phoneControl.value
        ? // User input a value => need validation
          !this.phoneControl?.errors?.validatePhoneNumber
        : // Value in input null
          // Check only if item is required (they should be not empty)
          !this.isRequired;

    this.validatePhoneNumber();
    if (this.isPhoneNumberValid) {
      selectedCountryDialCode = selectedCountryDialCode.indexOf('+') >= 0 ? selectedCountryDialCode : `+${selectedCountryDialCode}`;
      this.parentForm.get('mobilePhoneCountryCode').setValue(selectedCountryDialCode);
      const localPhone = this.phoneControl.value.replace(selectedCountryDialCode, '0');
      this.parentForm.get('mobilePhoneWithoutCountryCode').setValue(localPhone.indexOf('00') === 0 ? localPhone.slice(1) : localPhone);
    }
  }

  focusIn(): void {
    this.focus = true;
  }
}
