/* eslint-disable @typescript-eslint/no-explicit-any */

import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortable, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
// eslint-disable-next-line
/* eslint-disable max-lines */
import { forkJoin, merge, Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { StrongPointResponseDto } from '@commons-dto/valo-back';

import { ProspectAssociationDialogComponent } from '../../../dialog/components/prospect-association-dialog/prospect-association-dialog.component';
import { ProspectAssociation } from '../../../dialog/models/ProspectAssociation';
import { ProspectAssociationService } from '../../../dialog/services/prospect-association.service';
import { DocumentResponse } from '../../../utils/models/DocumentResponse';
import { LotSearchResponse } from '../../../utils/models/LotSearchResponse';
import { Sitemap } from '../../../utils/models/Sitemap';
import { TaxationResponse } from '../../../utils/models/TaxationResponse';
import { AppConfigService } from '../../../utils/services/app-config.service';
import { BasicFormatsService } from '../../../utils/services/basic-formats.service';
import { FileSystemService } from '../../../utils/services/file-system.service';
import { GoogleTagManagerService } from '../../../utils/services/google-tag-manager.service';
import { I18nService } from '../../../utils/services/i18n.service';
import { PoseOptionsService } from '../../../utils/services/pose-options.service';
import { ReferenceTablesService } from '../../../utils/services/reference-tables.service';
import { SnackbarService } from '../../../utils/services/snackbar.service';
import { ToggleFeesService } from '../../../utils/services/toggle-fees.service';
import { UserRoleService } from '../../../utils/services/user-role.service';
import { ORDER_BY_MAP, SearchFormUtilsService } from '../../services/search-form-utils.service';
import { SearchFormService } from '../../services/search-form.service';
import { FeatureFlagService } from '../../../feature-flag/feature-flag.service';
import { ProgramApiService } from '../../../adapters/program-api.service';
import { ProgramResponse } from '../../../utils/models/ProgramResponse';
import { SearchProgramData } from '../../model/search-program-data';
import { SearchCountResponse } from '../../model/search-count-response';
import { ManagementLotComparatorComponent } from '../../../comparator/components/management-lot-comparator/management-lot-comparator.component';
import { StrongPointListComponent } from '../../../design-system/component/strong-point-list/strong-point-list.component';
import { TakeOptionDialogComponent } from '../../../dialog/components/take-option-dialog/take-option-dialog.component';

@Component({
  selector: 'app-search-results-lots',
  templateUrl: './search-results-lots.component.html',
  styleUrls: ['./search-results-lots.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    NgClass,
    NgIf,
    RouterLink,
    NgFor,
    ManagementLotComparatorComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    StrongPointListComponent,
  ],
})
export class SearchResultsLotsComponent implements OnDestroy, AfterViewInit, OnInit {
  /**
   * columnTitles attribute
   *
   * @type {{ [key: string]: string }}
   * @memberof SearchResultsLotsComponent
   */
  public columnTitles: { [key: string]: string };

  /**
   * columnsToDisplay attribute
   *
   * @type {Array<string>}
   * @memberof SearchResultsLotsComponent
   */
  public columnsToDisplay: Array<string>;

  /**
   * dataSource attribute
   *
   * @type {MatTableDataSource<LotResponse>}
   * @memberof SearchResultsLotsComponent
   */
  public dataSource: MatTableDataSource<LotSearchResponse>;

  /**
   * expandedElement attribute
   *
   * @type {LotResponse | null}
   * @memberof SearchResultsLotsComponent
   */
  public expandedElement: LotSearchResponse | null;
  @Input() searchDataFilter: SearchProgramData;
  /**
   * simpleColumns attribute
   *
   * @type {Array<string>}
   * @memberof SearchResultsLotsComponent
   */
  public simpleColumns: Array<string>;
  pageSizeOptions: Array<number>;
  /**
   * property use for showing a waiting icnon while searching if the user can option
   * @private
   */
  public callCanOption: { [key: string]: boolean } = {};

  public isValo: boolean;

  @Input() public countResults: SearchCountResponse;
  @Output() private readonly refreshLotsEvent: EventEmitter<number> = new EventEmitter<number>();
  /**
   * optionUserAuthorized attribute
   *
   * @type {boolean}
   * @memberof SearchResultsLotsComponent
   */
  private readonly optionUserAuthorized: boolean;
  /**
   * Paginator for the MatTableDataSource
   *
   * @type {MatPaginator}
   * @memberof SearchResultsLotsComponent
   */
  private paginator: MatPaginator;
  /**
   * searchFormDataSubscription attribute
   *
   * @type {Subscription}
   * @memberof SearchResultsLotsComponent
   */
  private readonly tearDownSubscription: Subscription = new Subscription();
  /**
   * Sort for the MatTableDataSource
   *
   * @type {MatSort}
   * @memberof SearchResultsLotsComponent
   */
  private sort: MatSort;
  /**
   * List of all taxations from database
   *
   * @type {Array<TaxationResponse>}
   * @memberof SearchResultsLotsComponent
   */
  private taxations: Array<TaxationResponse>;
  /**
   * Taxation search in the search form
   *
   * @type {TaxationResponse}
   * @memberof SearchResultsLotsComponent
   */
  private taxationSearch: TaxationResponse;
  private queryParams: any;
  private orderBy: MatSortable;

  private currentLot: LotSearchResponse;

  public isOptionVisible: boolean;

  private isDossierProspectEnabled: boolean;
  private program: ProgramResponse;
  private companyId: number;

  constructor(
    public i18nService: I18nService,
    private readonly appConfigService: AppConfigService,
    private readonly fileSystemService: FileSystemService,
    private readonly basicFormatsService: BasicFormatsService,
    private readonly referenceTablesService: ReferenceTablesService,
    private readonly searchFormService: SearchFormService,
    private readonly snackbarService: SnackbarService,
    private readonly userRoleService: UserRoleService,
    private readonly toggleFeesService: ToggleFeesService,
    private readonly _poseOptionService: PoseOptionsService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _prospectAssociationService: ProspectAssociationService,
    private readonly _dialog: MatDialog,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly programService: ProgramApiService,
    private readonly featureFlagService: FeatureFlagService,
    public searchFormUtilsService: SearchFormUtilsService,
    public router: Router,
  ) {
    this.columnTitles = {
      program: 'Txt_Table_Column_Program',
      rooms: 'Txt_Table_Column_Rooms',
      livingSpace: 'Txt_Table_Column_LivingSpace',
      price: 'Txt_Table_Column_PriceIT',
      floorOrientation: 'Txt_Table_Column_FloorOrientation',
      strongPoints: 'Txt_Table_Column_StrongPoints',
      profitabilityRentingPrice: 'Txt_Table_Column_ProfitabilityRentingPrice',
      profitabilityRentingPriceMeuble: 'Txt_Table_Column_LoyerMeubleRentingPrice',
      deliveryDate: 'Txt_Table_Column_Delivery',
      fees: 'Txt_Table_Column_Fees',
      status: 'Txt_Table_Column_Status',
    };
    this.simpleColumns = Object.keys(this.columnTitles);
    this.removeFromSimpleColumns('program');
    this.removeFromSimpleColumns('status');
    this.removeFromSimpleColumns('strongPoints');
    this.tearDownSubscription.add(
      this.toggleFeesService.toggleFeesState$.subscribe((hideFees) => {
        if (hideFees) {
          this.removeFromSimpleColumns('fees');
        } else {
          this.addToSimpleColumns('fees', 6);
        }
      }),
    );

    this.dataSource = new MatTableDataSource();

    const roles = this.appConfigService.appConfig.roles;
    // List of authorized roles
    const authorizedRoles = [
      roles.valoSystem,
      roles.valoAdminSuper,
      roles.valoAdminSimple,
      roles.valoRcvSuper,
      roles.valoRcvSimple,
      roles.developerHyper,
      roles.developerSuper,
      roles.developerSimple,
      roles.contractorHyper,
      roles.contractorSuper,
      roles.contractorSimple,
    ];

    // Check if the user can use option
    this.optionUserAuthorized = this.userRoleService.isInRoles(authorizedRoles);

    // Display fees only for contractor
    if (this.userRoleService.isDeveloper()) {
      this.removeFromSimpleColumns('fees');
    }

    this.referenceTablesService.getTableReferenceInfo('Taxations').subscribe(
      (items: Array<TaxationResponse>) => {
        this.taxations = items.filter((item: TaxationResponse) => item.taxationType);
        this.search(this.taxations);
      },
      () => {
        this.snackbarService.sendErrorOccured();
      },
    );

    this.isOptionVisible = true;

    this.isDossierProspectEnabled = false;
  }

  private _lots: Array<LotSearchResponse>;

  public get lots(): Array<LotSearchResponse> {
    return this._lots;
  }

  /**
   * lots input
   *
   * @type {Array<LotResponse>}
   * @memberof SearchResultsLotsComponent
   */
  @Input()
  public set lots(lots: Array<LotSearchResponse>) {
    this._lots = lots;
    if (this._lots) {
      this._processStatus(this._lots);
    }
    if (this.dataSource) {
      this.dataSource.data = [...this.lots];
    }
  }

  @ViewChild(MatPaginator, { static: true }) set matPaginator(matPaginator: MatPaginator) {
    this.paginator = matPaginator;
  }

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }

  /**
   * getDeliveryDate method
   *
   * @param lot
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  private static getDeliveryDate(lot: LotSearchResponse, rawValue = false): string {
    if (lot.deliveryDate) {
      if (rawValue) {
        return String(lot.deliveryDate);
      }
      const date = new Date(lot.deliveryDate);

      return `${Math.trunc(date.getMonth() / 3) + 1}T ${date.getFullYear()}`;
    }

    return '-';
  }

  /**
   * getProgram method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  private static getProgram(lot: LotSearchResponse): string {
    return lot.programName ? lot.programName : '-';
  }

  ngOnInit(): void {
    this.queryParams = { ...this._route.snapshot.queryParams };
    this.tearDownSubscription.add(
      this._route.queryParams.subscribe((params) => {
        this.queryParams = { ...params };
      }),
    );
    this.isValo = this.userRoleService.isValo();

    this.featureFlagService.isEnabled('dossier-prospect').then((res) => {
      this.isDossierProspectEnabled = res;
    });
  }

  public ngAfterViewInit(): void {
    // Cannot be set before the view is rendered (like in the constructor) to avoid slow instantiation of the component
    this.initMatTableDataSource();

    this.orderBy = {
      id: this.queryParams.order || 'price',
      start: this.queryParams.direction ? ORDER_BY_MAP[this.queryParams.direction].toLowerCase() : 'asc',
      disableClear: false,
    };

    this.tearDownSubscription.add(
      merge(this.sort.sortChange, this.paginator.page).subscribe((event) => {
        // if the event is trigger by the sortChange set paginator page to 0
        if (
          // eslint-disable-next-line no-prototype-builtins
          event.hasOwnProperty('active') &&
          // eslint-disable-next-line no-prototype-builtins
          event.hasOwnProperty('direction')
        ) {
          this.paginator.pageIndex = 0;
        }

        this._setSortParams(this.sort);
        this.router
          .navigate([Sitemap.utils.search.path], {
            queryParams: this.queryParams,
            replaceUrl: true,
          })
          .then(() => {
            this.refreshLotsEvent.emit(this.paginator.pageIndex);
          });
      }),
    );

    this.dataSource.data = this.lots;
  }

  /**
   * clickOnTag method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public clickOnTag(lot: LotSearchResponse): void {
    this.expandedElement = this.expandedElement === lot ? undefined : lot;
  }

  /**
   * getColumnTitle method
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  public getColumnTitle(column: string): string {
    return this.columnTitles[column] ? this.i18nService._(this.columnTitles[column]) : column;
  }

  /**
   * getColumnValue method
   *
   * @param lot
   * @param column
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  public getColumnValue(lot: LotSearchResponse, column: string, rawValue = false): string | StrongPointResponseDto[] | number {
    switch (column) {
      case 'program':
        return SearchResultsLotsComponent.getProgram(lot);
      case 'city':
        return this.getCity(lot);
      case 'lotNumber':
        return this.getLotNumber(lot);
      case 'livingSpace':
        return this.getLivingSpace(lot, rawValue);
      case 'price':
        return this.getPrice(lot, rawValue);
      case 'floorOrientation':
        return this.getFloorAndOrientation(lot);
      case 'profitabilityRentingPrice':
        return this.getProfitabilityRentingPrice(lot, rawValue);
      case 'profitabilityRentingPriceMeuble':
        return this.profitabilityRentingPriceMeuble(lot);
      case 'fees':
        // VALO-5142 : If user is VALO => hide fees
        return !this.isValo ? this.getFees(lot, rawValue) : '-';
      case 'deliveryDate':
        return SearchResultsLotsComponent.getDeliveryDate(lot, rawValue);
      case 'status':
        return this.getStatus(lot);

      default:
        return lot[column] ? lot[column] : '-';
    }
  }

  /**
   * getExpandedTextReducedVATPrice method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public getExpandedTextReducedVATPrice(lot: LotSearchResponse): string {
    return lot.reducedTotalSellingPriceIT
      ? `${this.basicFormatsService.formatCurrency(lot.reducedTotalSellingPriceIT)} (${this.getPriceTag(lot)})`
      : '';
  }

  /**
   * getExpandedTextTaxationType method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public getExpandedTextTaxationType(lot: LotSearchResponse): string {
    return lot.taxationReduced && lot.taxationReduced.taxationType
      ? `${this.i18nService._(lot.taxationReduced.taxationType)} ${this.getPriceTag(lot)}`
      : '';
  }

  /**
   * getFloorAndOrientation method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public getFloorAndOrientation(lot: LotSearchResponse): string {
    return `${lot.floor || lot.floor === 0 ? lot.floor : '-'} / ${
      lot.lotOrientationLabel ? this.i18nService._(lot.lotOrientationLabel) : '-'
    }`;
  }

  /**
   * getPriceTag method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public getPriceTag(lot: LotSearchResponse): string {
    return lot.taxationReduced && lot.taxationReduced.taxation
      ? `${this.i18nService._('Txt_Page_Program_VAT')} ${this.basicFormatsService.formatPercentage(lot.taxationReduced.taxation / 100)}`
      : '-';
  }

  /**
   * redirectToProgramPage method
   *
   * @param programId
   * @memberof SearchResultsLotsComponent
   */
  public redirectToProgramPage(programId: number): string {
    return `/${Sitemap.programs.read.path.replace(/:programId/, String(programId))}`;
  }

  /**
   * getTaxationsLabels method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public getTaxationsLabels(lot: LotSearchResponse): Array<string> {
    return lot.labelsProfitability.split(',').sort();
  }

  /**
   * isPriceWithReducedTaxation method
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  public isPriceWithReducedTaxation(column: string): boolean {
    return column === 'price' && this.taxationSearch && this.taxationSearch.reducedTaxation;
  }

  /**
   * isPriceWithSpecialOffer method
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  public isPriceWithSpecialOffer(column: string, lot: LotSearchResponse): boolean {
    return column === 'price' && this.hasSpecialOffer(lot);
  }

  /**
   * isSortable method
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  public isSortable(column: string): boolean {
    return column !== 'floorOrientation';
  }

  /**
   * hasSpecialOffer method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public hasSpecialOffer(lot: LotSearchResponse): boolean {
    return lot.nbSpecialOffers !== 0;
  }

  /**
   * ngOnDestroy method
   *
   * @memberof SearchResultsLotsComponent
   */
  public ngOnDestroy(): void {
    this.tearDownSubscription.unsubscribe();
  }

  /**
   * openPlan method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  public openPlan(lot: LotSearchResponse, $event): void {
    $event.stopImmediatePropagation();
    const document: DocumentResponse = {
      title: lot.lotPlanTitle,
      fileName: lot.lotPlanFileName,
      container: lot.lotPlanContainer,
      mimeType: lot.lotPlanMimeType,
    };
    this.pushDataLayer(lot, 'download_plan');
    this.fileSystemService.openDocumentInBrowser(document, document.title);
  }

  /**
   * Method called by material table trackBy
   *
   * @param lot tracked lot
   */
  public trackLots(lot: LotSearchResponse): number {
    return lot.lotId;
  }

  checkValidity(lot: LotSearchResponse) {
    // Here we open the dialog
    this.callCanOption[lot.lotId] = true;
    this.pushDataLayer(lot, 'click_option');
    forkJoin([
      this._poseOptionService.checkValidity(lot.lotId),
      this._poseOptionService.getActionOnOption(lot.lotId),
      this.programService.getProgramInformationById(lot.programId),
    ]).subscribe(
      (response) => {
        delete this.callCanOption[lot.lotId];
        const validity = response[0];
        const actionOnOption = response[1];
        this.program = response[2];
        this.companyId = this.program.companyId;
        if (validity) {
          this.currentLot = lot;
          this.openDialogModal(actionOnOption);
        } else {
          this.snackbarService.sendErrorOccured();
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (error) => {
        delete this.callCanOption[lot.lotId];
        this.snackbarService.sendErrorOccured();
      },
    );
  }

  openDialogModal(actionOnOption): void {
    const dialogData: ProspectAssociation = this.getDialogData(actionOnOption);
    let dialogRef;

    if (this.isDossierProspectEnabled) {
      dialogRef = this._dialog.open(TakeOptionDialogComponent, {
        autoFocus: false,
        data: dialogData,
      });
    } else {
      dialogRef = this._dialog.open(ProspectAssociationDialogComponent, {
        autoFocus: false,
        data: dialogData,
      });
    }

    // Reload Component according to response
    this.subscribeToClosedDialogForOptionnedLot(dialogRef);
  }

  subscribeToClosedDialogForOptionnedLot(dialogRef) {
    dialogRef.afterClosed().subscribe((lotIdUpdateToOptionned: number) => {
      if (lotIdUpdateToOptionned) {
        this._googleTagManagerService.pushTag({
          event: 'validation_option',
        });
        this._prospectAssociationService.setLotIdUpdateToOption({
          lotIdUpdateStatus: lotIdUpdateToOptionned,
          isOptionned: true,
          labelStatus: this.appConfigService.getAppConfig().statusLotLabels.optioned,
        });
        this.refreshLotsEvent.emit(this.paginator.pageIndex);
        this.pushDataLayer(this.currentLot, 'validation_option');
      }
    });
  }

  getDialogData(actionOnOption): ProspectAssociation {
    return {
      lotId: this.currentLot.lotId,
      lotNumber: this.currentLot.lotNumber,
      lotPrice: `${this.currentLot.price}`,
      title: actionOnOption.checkIfOptionCanBeAutomaticallyTaken
        ? 'Title_ProspectAssociationPopin_PopinTitle'
        : 'Title_ProspectAssociationPopin_PopinTitleAsk',
    };
  }

  private _setSortParams(sort) {
    const lastSort = { ...this.orderBy };
    this.orderBy = {
      id: sort.active,
      start: sort.direction,
      disableClear: false,
    };
    if (sort.direction) {
      Object.assign(this.queryParams, {
        order: sort.active,
        direction: ORDER_BY_MAP[sort.direction.toUpperCase()],
      });
    } else {
      delete this.queryParams.order;
      delete this.queryParams.direction;
    }
    if (lastSort.id !== this.orderBy.id || lastSort.start !== this.orderBy.start) {
      this.searchFormUtilsService.pushSortChangeDatalayer({
        label: sort.active,
        direction: sort.direction.toUpperCase(),
      });
    }
  }

  private pushDataLayer(lot: LotSearchResponse, event: string) {
    this._googleTagManagerService.pushTag({
      program: {
        id: lot.programId,
      },
      lot: {
        Id: lot.lotId,
        roomNumber: lot.rooms,
        price: lot.price,
        taxation: this.getTaxationsLabels(lot),
        surface: lot.livingSpace,
        status: lot.status,
        type: lot.lotTypeLabel,
        tva: '',
        delivery: lot.deliveryDate,
        orientation: lot.lotOrientationLabel,
        profitability: lot.profitability ? lot.profitability : undefined,
        rent: lot.estmatedMonthlyRentingPrice ? lot.estmatedMonthlyRentingPrice : undefined,
        specialOffer: lot.nbSpecialOffers,
        excluValo: '',
        terrace: '',
        balcony: '',
        garden: '',
        loggia: '',
        annexIncluded: '',
        feeRate: lot.fees,
      },
      event,
    });
  }

  private search(taxations: Array<TaxationResponse>) {
    this.tearDownSubscription.add(
      this.searchFormService.getSearchProgramData().subscribe((searchFormData: SearchProgramData) => {
        if (searchFormData && searchFormData.where) {
          this.taxationSearch = taxations.find((taxation) => taxation.id === searchFormData.where.taxation);
        }
      }),
    );
  }

  /**
   * getCity method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  private getCity(lot: LotSearchResponse): string {
    const postalCode = this.basicFormatsService
      .formatDecimal(parseInt(lot.postalCode, 10), 5, 0, 0)
      .replace(/[\s.,]+/g, '')
      .slice(0, -3);

    return `${lot.city}
      (${postalCode})
      - ${lot.lotTypeLabel ? this.i18nService._(lot.lotTypeLabel) : ''}`;
  }

  /**
   * getFees method
   *
   * @param lot
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  private getFees(lot: LotSearchResponse, rawValue = false): string | number {
    return rawValue ? (lot.fees ? lot.fees : 0) : lot.fees ? this.basicFormatsService.formatPercentage(lot.fees / 100) : '-';
  }

  /**
   * getLivingSpace method
   *
   * @param lot
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  private getLivingSpace(lot: LotSearchResponse, rawValue = false): string | number {
    return rawValue
      ? lot.livingSpace
        ? lot.livingSpace
        : Number.MAX_SAFE_INTEGER - 1
      : lot.livingSpace
      ? `${this.basicFormatsService.formatDecimal(lot.livingSpace)} ${this.i18nService._('Txt_Table_SquareMeter')}`
      : '-';
  }

  /**
   * getLotNumber method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  private getLotNumber(lot: LotSearchResponse): string {
    return lot.lotNumber ? `${this.i18nService._('Txt_Table_LotNumber')} ${lot.lotNumber}` : '-';
  }

  /**
   * getPrice method
   *
   * @param lot
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  private getPrice(lot: LotSearchResponse, rawValue = false): string | number {
    let price = lot.commercialLotSellingPriceIT;
    if (this.taxationSearch && this.taxationSearch.reducedTaxation && this.getTaxationsLabels(lot).length === 1) {
      price = lot.reducedTotalSellingPriceIT;
    }

    return rawValue ? (price ? price : Number.MAX_SAFE_INTEGER - 1) : price ? this.basicFormatsService.formatCurrency(price) : '-';
  }

  /**
   * getProfitabilityRentingPrice method
   *
   * @param lot
   * @param rawValue
   * @memberof SearchResultsLotsComponent
   */
  private getProfitabilityRentingPrice(lot: LotSearchResponse, rawValue = false): string | number {
    if (!lot.labelsProfitability.includes('LMNP/LMP')) {
      const rowValStr = lot.estimatedProfitability ? lot.estimatedProfitability : Number.MAX_SAFE_INTEGER - 1;
      const profitability = lot.estimatedProfitability ? this.basicFormatsService.formatPercentage(lot.estimatedProfitability / 100) : '-';
      const rent = lot.estmatedMonthlyRentingPrice ? this.basicFormatsService.formatCurrency(lot.estmatedMonthlyRentingPrice) : '-';
      const profitablityAndRent = `${profitability} / ${rent}`;

      return rawValue ? rowValStr : profitablityAndRent;
    }
    return '-';
  }

  private profitabilityRentingPriceMeuble(lot: LotSearchResponse): string | number {
    return lot.profitabilityRentingPriceMeuble;
  }

  /**
   * getStatus method
   *
   * @param lot
   * @memberof SearchResultsLotsComponent
   */
  private getStatus(lot: LotSearchResponse): string {
    const statusLotLabels = this.appConfigService.getAppConfig().statusLotLabels;
    let statusFormat = '';
    switch (lot.status) {
      case statusLotLabels.free:
        statusFormat = this.i18nService._('Txt_Detail_Program_Status_Lot_Free');
        break;
      case statusLotLabels.unavailable:
        statusFormat = this.i18nService._('Txt_Detail_Program_Status_Lot_Unavailable');
        break;
      case statusLotLabels.optioned:
        statusFormat = this.i18nService._('Txt_Detail_Program_Status_Lot_Optioned');
        break;
      default:
        break;
    }

    return statusFormat;
  }

  /**
   * Init dataSource
   *
   * @memberof SearchResultsLotsComponent
   */
  private initMatTableDataSource(): void {
    this.dataSource.sortingDataAccessor = (data: LotSearchResponse, sortHeaderId: string): string | number => {
      const value = this.getColumnValue(data, sortHeaderId, true);

      if (typeof value === 'number') {
        return value;
      } else {
        return value.toString().toUpperCase();
      }
    };
  }

  /**
   * Remove column name from simpleColumns list and update columnsToDisplay list
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  private removeFromSimpleColumns(column: string): void {
    const index: number = this.simpleColumns.indexOf(column);
    // avoid splice to remove an item if there is no occurrence of the column
    if (index !== -1) {
      this.simpleColumns.splice(this.simpleColumns.indexOf(column), 1);
      this.updateColumnToDisplay();
    }
  }

  /**
   * Add column to simpleColumns list and update columnsToDisplay list
   *
   * @param column
   * @memberof SearchResultsLotsComponent
   */
  private addToSimpleColumns(column: string, index: number): void {
    const foundIndex: number = this.simpleColumns.indexOf(column);
    // do not add column if it already is in table
    if (foundIndex === -1) {
      this.simpleColumns.splice(index, 0, column);
      this.updateColumnToDisplay();
    }
  }

  /**
   * merge fix and dynamic columns
   */
  private updateColumnToDisplay(): void {
    this.columnsToDisplay = ['program', ...this.simpleColumns, 'strongPoints', 'status'];
  }

  private _processStatus(_lots: Array<LotSearchResponse>) {
    _lots.forEach((lot) => {
      lot.status = lot.status.substring(1);
    });
  }
}
